import styled from 'styled-components'

export const Prohibit = styled('span')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  height: '30%',
  width: '100%',
  right: '0.4rem',
  bottom: '0.4rem',
  svg: {
    '&:nth-of-type(1)': {
      filter: 'drop-shadow(0.25px 0.25px 0.25px rgb(0 0 1/0.4))',
    },
  },
})
