import { CarouselProvider } from 'pure-react-carousel'
import styled from '@mui/material/styles/styled'
import { CustomTheme } from '../../../types/theme'
import { Swiper } from 'swiper/react'

import { CarouselProgressIndicatorProps, ColorVariant, ColorVariantMapped, ShapeVariant, ShapeVariantMapped, StyledCarouselProps } from './Carousel.type'

export const mapVariant = (theme: CustomTheme, variant: ColorVariant = 'dark', shapeVariant?: ShapeVariant): ColorVariantMapped => {
  const themeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    dark: {
      background: theme.palette.custom.black,
      text: theme.palette.custom.white,
      hoverText: theme.palette.custom.white,
      hoverBackground: theme.palette.custom.light1.grey,
      hoverPaginationBackground: theme.palette.custom.light2.grey,
      buttonBackground: 'linear-gradient(to right, rgb(0 0 0) 50%,rgb(0 0 0 / 0%) 100%)',
    },
    green: {
      background: theme.palette.custom.halfBaked,
      text: theme.palette.custom.cyprus,
      hoverText: theme.palette.custom.white,
      hoverBackground: theme.palette.custom.jaggedIce,
      hoverPaginationBackground: theme.palette.custom.light1.grey,
      buttonBackground: theme.palette.custom.halfBaked,
    },
    light: {
      background: theme.palette.custom.light.grey,
      text: theme.palette.custom.black,
      hoverText: shapeVariant === 'rectangle' ? theme.palette.custom.white : theme.palette.custom.black,
      hoverBackground: shapeVariant === 'rectangle' ? theme.palette.custom.light1.grey : theme.palette.custom.white,
      hoverPaginationBackground: theme.palette.custom.light2.grey,
      buttonBackground: 'linear-gradient(to right, rgb(255 255 255) 50%,rgba(255,255,255,0) 100%)',
    },
    blue: {
      background: theme.palette.custom.blue,
      text: theme.palette.custom.white,
      hoverText: theme.palette.custom.blue,
      hoverBackground: theme.palette.custom.light.blue,
      hoverPaginationBackground: theme.palette.custom.light.blue,
      buttonBackground: theme.palette.custom.blue,
    },
  }
  const transparentThemeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    ...themeStyles,
    dark: {
      background: 'transparent',
      text: theme.palette.custom.black,
      hoverText: theme.palette.custom.light2.grey,
      hoverBackground: 'transparent',
      hoverPaginationBackground: theme.palette.custom.light2.grey,
      buttonBackground: theme.palette.custom.black,
    },
    light: {
      background: 'transparent',
      text: theme.palette.custom.white,
      hoverText: theme.palette.custom.light2.grey,
      hoverBackground: 'transparent',
      hoverPaginationBackground: theme.palette.custom.light2.grey,
      buttonBackground: theme.palette.custom.black,
    },
    blue: {
      background: 'transparent',
      text: theme.palette.custom.blue,
      hoverText: theme.palette.custom.light.blue,
      hoverBackground: 'transparent',
      hoverPaginationBackground: theme.palette.custom.light.blue,
      buttonBackground: theme.palette.custom.black,
    },
  }

  if (shapeVariant === 'transparent')
    return transparentThemeStyles[variant]

  return themeStyles[variant]
}

export const mapControllerVariant = (theme: CustomTheme, variant: ColorVariant = 'dark', shadowPosition: 'to left' | 'to right' = 'to right'): ColorVariantMapped => {
  const themeStyles: {
    [K in ColorVariant]: ColorVariantMapped
  } = {
    dark: {
      buttonBackground: `linear-gradient(${shadowPosition}, rgb(0 0 0) 50%,rgb(0 0 0 / 0%) 100%)`,
    },
    green: {
      buttonBackground: theme.palette.custom.halfBaked,
    },
    light: {
      buttonBackground: `linear-gradient(${shadowPosition}, rgb(255 255 255) 50%,rgba(255,255,255,0) 100%)`,
    },
    blue: {
      buttonBackground: theme.palette.custom.blue,
    },
  }

  return themeStyles[variant]
}

export const mapShapeVariant = (theme: CustomTheme, variant: ShapeVariant = 'round'): ShapeVariantMapped => {
  const themeStyles: {
    [K in ShapeVariant]: ShapeVariantMapped
  } = {
    round: {
      borderRadius: 18,
      height: undefined,
      boxShadow: theme.shadows[19],
      fontSize: '0.75rem',
    },
    rectangle: {
      borderRadius: 0,
      height: theme.spacing(24),
      boxShadow: theme.shadows[19],
      fontSize: '0.75rem',
    },
    transparent: {
      borderRadius: 0,
      height: undefined,
      boxShadow: undefined,
      fontSize: '1.125rem',
    }
  }
  return themeStyles[variant]
}

export const StyledCarouselProvider = styled(CarouselProvider, {
  name: 'Carousel',
  slot: 'Provider',
})(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  button: {
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
      '& > div': {
        pointerEvents: 'none',
      }
    }
  },
  '&.button-carousel': {
    position: 'absolute',
    padding: 0,
    border: 'none',
    background: 'unset',
    zIndex: 1,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '&.progress-bar-container': {
    marginTop: '2rem',
  },
  'li': {
    listStyleType: 'none',
    marginRight: '0.5rem',
    '&.carousel__inner-slide': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    'img':  {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      }
    }
  }
}))

export const CarouselFullControllerStyle = styled('div', {
  name: 'Carousel',
  slot: 'FullController',
  shouldForwardProp: (prop) => (
    prop !== 'colorVariant'
  )
})<Pick<StyledCarouselProps, 'colorVariant'>>(({ theme, colorVariant }) => ({
  minWidth: 'auto',
  width: '2rem',
  height: '6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: mapVariant(theme, colorVariant).background,
  transition: '0.15s ease',
  svg: {
    width: '1rem',
    height: '1rem',
    fill: mapVariant(theme, colorVariant).text,
  },
  '&:hover': {
    backgroundColor: mapVariant(theme, colorVariant).hoverBackground,
  }
}))

export const CarouselOutlinedControllerStyle = styled('section', {
  name: 'Carousel',
  slot: 'OutlinedController',
})(() => ({
  width: '80px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: '2px solid white',

  span: {
    userSelect: 'none',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: 'white',
    transition: '0.25s ease',
  },

  '&:hover': {
    'span': {
      transform: 'translateY(2px)',
    }
  }
}))

const mapVariantIndicator = (
  theme: CustomTheme,
  variant: ColorVariant = 'dark'
) => {
  const themeStyles = {
    dark: {
      background: `${theme.palette.custom.white}3C`,
      line: theme.palette.custom.white,
    },
    light: {
      background: theme.palette.custom.alto,
      line: theme.palette.custom.cyprus,
    },
  }
  return themeStyles[variant === 'green' ? 'dark' : variant]
}

export const CarouselProgressIndicator = styled('div', {
  name: 'Carousel',
  slot: 'ProgressIndicator',
  shouldForwardProp: (prop) => (
    prop !== 'percentage' && prop !== 'colorVariant'
  )
})<CarouselProgressIndicatorProps>(({ theme, percentage, colorVariant }) => ({
  width: '12rem',
  height: '0.125rem',
  position: 'relative',
  margin: '0 auto',
  backgroundColor:
    mapVariantIndicator(theme, colorVariant).background,

  '&::after': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${percentage}`,
    height: '100%',
    backgroundColor: mapVariantIndicator(theme, colorVariant).line,
  },
}))

export const StyledSwiper = styled(Swiper, {
  name: 'Styled',
  slot: 'Swiper',
  shouldForwardProp: prop =>
    prop !== 'paginationwidth' &&
    prop !== 'colorvariant' &&
    prop !== 'paginationvariant' &&
    prop !== 'shapevariant' &&
    prop !== 'slideviewpercent' &&
    prop !== 'showNextSlide' &&
    prop !== 'maxWidthForImages',
})<{
  paginationwidth?: number
  colorvariant?: ColorVariant
  paginationvariant?: ColorVariant
  shapevariant?: ShapeVariant
  darkmode?: number
  slideviewpercent?: number
  showNextSlide?: boolean
  maxWidthForImages?: string
}>(
  ({
    theme,
    paginationwidth,
    colorvariant = 'light',
    paginationvariant = 'dark',
    shapevariant = 'round',
    slideviewpercent = 0,
    maxWidthForImages = 'inherit',
  }) => ({
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    '.swiper-wrapper': {
      marginBottom: theme.spacing(10),

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(16),
      },
    },
    '.swiper-slide': {
      width: `${slideviewpercent > 0 ? slideviewpercent + '% !important' : 'unset'}`,
      transition: 'width 1s',
    },
    '.swiper-button-prev, .swiper-button-next': {
      width: theme.spacing(16),
      height: '104%', //Just a container
      top: 18,
      '&:after': {
        color: mapVariant(theme, colorvariant, shapevariant).text,
        fontSize: mapShapeVariant(theme, shapevariant).fontSize,
        borderRadius: mapShapeVariant(theme, shapevariant).borderRadius,
        background: mapVariant(theme, colorvariant, shapevariant).background,
        width: 32,
        fontWeight: 'bolder',
        boxShadow: mapShapeVariant(theme, shapevariant).boxShadow,
        display: 'flex',
        alignItems: 'center',
        height: mapShapeVariant(theme, shapevariant).height,
      },
      '&:hover:after': {
        background: mapVariant(theme, colorvariant, shapevariant).hoverBackground,
        color: mapVariant(theme, colorvariant, shapevariant).hoverText,
      },
      [theme.breakpoints.down(769)]: {
        display: 'none',
      },
    },
    '.swiper-button-next': {
      right: '0',
      background: 'transparent',
      '&:after': {
        padding: '10px 14px',
      },
    },
    '.swiper-button-prev': {
      left: '0px',
      background: 'transparent',
      '&:after': {
        padding: '10px 12px',
      },
    },
    '.swiper-pagination': {
      bottom: '34px !important',
    },
    '.swiper-pagination-bullet': {
      borderRadius: 0,
      width: `${paginationwidth ? paginationwidth : 60}px`,
      height: 2,
      opacity: 1,
      background: '#6e6e6e',
      marginBottom: '-40px !important',

      '&.swiper-pagination-bullet-active': {
        background: mapVariant(theme, paginationvariant).background,
      },
      [theme.breakpoints.down(769)]: {
        width: `${paginationwidth ? paginationwidth : 14}px`,
      },
    },
    img: {
      maxWidth: maxWidthForImages,
    },
  })
)
