import { IProduct } from './product'

// #region TYPE DEFINITIONS

export const DYNAMIC_CONTENT_KEYS = {
  ANTI_REFLECTIVE: 'ANTI_REFLECTIVE',
  ANTI_SCRATCH: 'ANTI_SCRATCH',
  FACE_SHAPE: 'FACE_SHAPE',
  FRAME_SHAPE: 'FRAME_SHAPE',
  GENDER: 'GENDER',
  GEOFIT: 'GEOFIT',
  IS_NEW: 'IS_NEW',
  LIMITED_EDITION: 'LIMITED_EDITION',
  PHOTOCHROMIC: 'PHOTOCHROMIC',
  POLARIZED: 'POLARIZED',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  ROXABLE: 'ROXABLE',
  SPECIAL_PROJECT_FLAG: 'SPECIAL_PROJECT_FLAG',
  SPECIAL_PROJECT_DESCRIPTION: 'SPECIAL_PROJECT_DESCRIPTION',
  SPECIAL_PROJECT_SPONSOR: 'SPECIAL_PROJECT_SPONSOR',
  SPECIAL_PROJECT_TYPE: 'SPECIAL_PROJECT_TYPE',
  SUSTAINABILITY_CLAIM: 'SUSTAINABILITY_CLAIM',
  UV_PROTECTION: 'UV_PROTECTION',
  FRAME_SHAPE_FACET: 'FRAME_SHAPE_FACET',
  FRONT_COLOR_FACET: 'FRONT_COLOR_FACET',
  LENS_COLOR_FACET: 'LENS_COLOR_FACET',
  LENS_TREATMENT_FACET: 'LENS_TREATMENT_FACET',
} as const

export const {
  ANTI_REFLECTIVE,
  ANTI_SCRATCH,
  FACE_SHAPE,
  FRAME_SHAPE,
  GENDER,
  GEOFIT,
  IS_NEW,
  LIMITED_EDITION,
  PHOTOCHROMIC,
  POLARIZED,
  PRODUCT_TYPE,
  ROXABLE,
  SPECIAL_PROJECT_FLAG,
  SPECIAL_PROJECT_DESCRIPTION,
  SPECIAL_PROJECT_SPONSOR,
  SPECIAL_PROJECT_TYPE,
  SUSTAINABILITY_CLAIM,
  UV_PROTECTION,
  FRAME_SHAPE_FACET,
  FRONT_COLOR_FACET,
  LENS_COLOR_FACET,
  LENS_TREATMENT_FACET,
} = DYNAMIC_CONTENT_KEYS

export type DynamicContentKey = (typeof DYNAMIC_CONTENT_KEYS)[keyof typeof DYNAMIC_CONTENT_KEYS]

export const dynamicContentKeys: DynamicContentKey[] = [
  ANTI_REFLECTIVE,
  ANTI_SCRATCH,
  FACE_SHAPE,
  FRAME_SHAPE,
  GENDER,
  GEOFIT,
  IS_NEW,
  LIMITED_EDITION,
  PHOTOCHROMIC,
  POLARIZED,
  PRODUCT_TYPE,
  ROXABLE,
  SPECIAL_PROJECT_FLAG,
  SPECIAL_PROJECT_DESCRIPTION,
  SPECIAL_PROJECT_SPONSOR,
  SPECIAL_PROJECT_TYPE,
  SUSTAINABILITY_CLAIM,
  UV_PROTECTION,
  FRAME_SHAPE_FACET,
  FRONT_COLOR_FACET,
  LENS_COLOR_FACET,
  LENS_TREATMENT_FACET,
]

export type DynamicContentRule = {
  key: DynamicContentKey
  value: string
}

export type DynamicRule = {
  rules: DynamicContentRule[]
  target: ILXTeaser | ICMCollection
}

export type IViewType =
  | 'full-width-banner'
  | 'two-column-banner-lg'
  | 'fullwidth-promo-banner'
  | 'fullwidth-promo-banner-2'
  | 'fullwidth-promo-banner-3'
  | 'fullwidth-promo-banner-4'
  | 'fullwidth-banner-collection-promo'
  | 'fullwidth-banner-collection-promo-multiple'
  | 'full-width-banner-with-shadow'
  | 'landscape-banner'
  | 'landscape-promo-banner'
  | 'landscape-promo-banner-2'
  | 'landscape-promo-banner-3'
  | 'landscape-banner-collection-promo'
  | 'landscape-banner-collection-promo-multiple'
  | 'complex-table'
  | 'index-table'
  | 'star-table'
  | 'simple-table'
  | 'single-column-info-article'
  | 'double-column-info-article'
  | 'banner-carousel-modal'
  | 'feature-list-modal'
  | 'top-page-banner'
  | 'top-page-promo'
  | 'top-page-promo-2'
  | 'top-page-promo-3'
  | 'top-page-banner-collection-promo'
  | 'top-page-banner-collection-promo-multiple'
  | 'plp-box'
  | 'banner-with-side-fields-plus-related'
  | 'default'
  | 'text-module'
  | 'banner-with-side-fields'
  | 'blurred-slider'
  | 'narrow-banner-w-all-fields-below'
  | 'box-plus-one'
  | 'box-with-margin'
  | 'boards-with-fields'
  | 'boards-with-fields-on-below-mobile-no-carousel'
  | 'boards-with-fields-on-below-mobile-carousel'
  | 'boards-with-fields-below'
  | 'grid-of-boards-two-columns'
  | 'grid-of-boards-three-columns'
  | 'grid-of-boards-four-columns'
  | 'combo-mini-slider-plus-box-all-fields'
  | 'products'
  | 'grid-of-products'
  | 'mini-slider'
  | 'open-article_without_title'
  | 'categories-with-icon'
  | 'group-of-menu-items'
  | 'big-menu-banner'
  | 'banners-with-icons'
  | 'list-of-products'
  | 'list-of-brand-icons'
  | 'list-of-brands'
  | 'book-an-appointment-plus-search-box'
  | 'plp-one-tile'
  | 'plp-two-tiles'
  | 'plp-three-tiles'
  | 'squat-banner'
  | 'top-page-banner'
  | 'square-boards-with-split'
  | 'square-boards-without-split'
  | 'faqs'
  | 'faqs-with-link'
  | 'faq-search'
  | 'two-item-board-carousel'
  | 'three-item-board-carousel'
  | 'four-more-board-carousel'
  | 'mod-box-with-margin'
  | 'board-with-icons'
  | 'instagram-carousel'
  | 'board-with-6-icons'
  | 'board-with-4-icons'
  | 'mod-box-without-margin-products'
  | 'mod-box-with-margin-products'
  | 'box-product-carousel'
  | 'mod-box-without-margin'
  | 'mod-box-without-margin-products'
  | 'wall-of-brands'
  | 'wall-of-brands-with-tabs'
  | 'four-products-carousel'
  | 'mod-box-with-margin-products'
  | 'four-item-board-carousel'
  | 'four-item-board-carousel-slide'
  | 'four-item-board-carousel-tile'
  | 'text-module-1-default'
  | 'double-percent-off-text'
  | 'multiple-percent-off-text'
  | 'multiple-percent-off-text-without-code'
  | 'grid-of-products'
  | 'three-products-carousel'
  | 'cly-products-category-tabs'
  | 'cly-four-products-category-tabs'
  | 'cly-products'
  | 'cly-products-w-cta'
  | 'pdp-banner'
  | 'plain-slider'
  | 'three-products-carousel-slide'
  | 'you-may-also-like'
  | 'query-list'
  | 'box-without-margin'
  | 'boards-with-fields-below-stack'
  | 'value-proposition-banner'
  | 'cly-category-with-cta'
  | 'box-and-2-products'
  | 'box-and-4-products'
  | null

export type ILXTeaserName =
  | 'PromoInfoTextv1-1'
  | 'PromoInfoTextv1-2'
  | 'PromoInfoTextv1-3'
  | 'PromoInfoTextv2-1'
  | 'PromoInfoTextv2-2'
  | 'PromoInfoTextv2-3'
  | 'Divider'
  | null

export type IBannerType = 'full-width-banner' | 'landscape-banner' | 'top-page-banner' | null

export type IPLPBoxOrientation = 'left' | 'right'
export type IGridPositioning = 1 | 2 | 3

export interface IPictureMedia {
  type: 'CMPicture'
  uriTemplate: string
  alt: string
}

export interface IVideoMedia {
  type: 'CMVideo'
  autoplay: boolean
  uriTemplate: string
  data: {
    uri: string
  }
  dataUrl: string
  hideControl: boolean
  loop: boolean
  mute: boolean
  picture: {
    uriTemplate: string
  }
  playOnHover: boolean
  width: number
}

export type TMedia = IVideoMedia | IPictureMedia

export interface ICallToAction {
  callToActionEnabled?: boolean
  callToActionText?: string
  // TODO remove 'cta-fill-secondary-light' when not needed
  style: 'arn-cta--primary' | 'arn-cta--secondary' | 'arn-cta--tertiary' | 'cta-fill-secondary-light'
  target:
    | ICMChannel
    | ICMExternalPage
    | ICMProductTeaser
    | ICMExternalChannel
    | ICMExternalProduct
    | ICMDownload
    | ICMExternalLink
    | ICMArticle
    | ICMLXTeaser
    | IAction
    | CouponAction
}

type TTeaserLabelPositions =
  | 'left-top'
  | 'center-top'
  | 'right-top'
  | 'left-center'
  | 'center-center'
  | 'right-center'
  | 'left-bottom'
  | 'center-bottom'
  | 'right-bottom'
  | 'top-left'
  | ''

export type TOverlayTextAlign = React.CSSProperties['textAlign']

export interface ICMNavigationPath {
  segment: string
}

// Base Placement Item
// This must contain shared memebers
export interface IBasePlacementItem {
  id?: string
  name?: string
  viewtype: IViewType
}

export interface ICMProductTeaser extends IBasePlacementItem {
  type: 'CMProductTeaser'
  externalId: string
  formattedUrl: string
  media: TMedia[]
  teaserTitle: string
  teaserText: string | null
  productData: IProduct
  url?: string
}

export interface ICMTeaserTarget extends IBasePlacementItem {
  type: 'CMTeaserTarget'
  externalId: string
  media: TMedia[]
  title: string
  teaserText: string | null
}
export interface ICMExternalProduct extends IBasePlacementItem {
  externalId: string
  formattedUrl: string
  media: TMedia[]
  productData: IProduct
  title: string
  teaserTitle: string
  teaserText: string | null
  type: 'CMExternalProduct'
  url?: string
}

export interface ICMLXTeaser extends IBasePlacementItem {
  type: 'LXTeaser'
  idAction: string
  teaserLXCallToActionSettings: ICallToAction[]
  teaserText1: string
  teaserTitle1: string
  formattedUrl: string
  url: string
  title: string
  media: TMedia[]
}

export interface ICMExternalLink extends IBasePlacementItem {
  formattedUrl: string
  title: string
  teaserTitle: string
  type: 'CMExternalLink'
  url: string
  media: TMedia[]
}

export interface ICMExternalChannel extends IBasePlacementItem {
  externalId: string
  formattedUrl: string
  title: string
  type: 'CMExternalChannel'
  navigationPath: ICMNavigationPath[]
  media: TMedia[]
  brandData: {
    id: string
    name: string
    formattedUrl: string
    shortDescription: string
  }[]
  url?: string
}

export interface ICMChild {
  children: ICMChild[]
  formattedUrl: string
  hidden: boolean
  hiddenInDesktopNavigation: boolean
  hiddenInFooterNavigation: boolean
  hiddenInMobileNavigation: boolean
  hiddenInSearchAndSitemap: boolean
  hiddenInSitemap: boolean
  name: string
  navigationPath: ICMNavigationPath[]
  showChildrenInMenu: boolean
  title: string
  url: string
  type: string
  teaserTargets: ITeaserTargets[]
}

export interface ICMHtml extends IBasePlacementItem {
  children?: ICMChild[]
  formattedUrl: string
  resourcelink: string
  html: string
  title: string
  type: 'CMHTML'
  media: TMedia[]
}

export interface ICMExternalPage extends IBasePlacementItem {
  children?: ICMChild[]
  externalId: string
  formattedUrl: string
  title: string
  type: 'CMExternalPage'
  navigationPath: ICMNavigationPath[]
  media: TMedia[]
  url?: string
}

export type TOverlaySettings =
  | 'block-left-top'
  | 'block-left-middle'
  | 'block-left-bottom'
  | 'block-center-top'
  | 'block-center-middle'
  | 'block-center-bottom'
  | 'block-right-top'
  | 'block-right-middle'
  | 'block-right-bottom'

export type TeaserOverlayStyle =
  | 'text-dark-primary'
  | 'text-dark-primary-shadow'
  | 'text-dark-secondary'
  | 'text-dark-secondary-shadow'
  | 'text-light-primary'
  | 'text-light-primary-shadow'
  | 'text-light-secondary'
  | 'text-light-secondary-shadow'
  | ''

export type TeaserBackGround =
  | 'bg-dark-primary'
  | 'bg-dark-secondary'
  | 'bg-light-primary'
  | 'bg-light-secondary'
  | 'bg-light-tertiary'
  | 'bg-light-quaternary'
  | 'bg-black'
  | 'bg-dark-one'
  | 'bg-light-green'
  | 'bg-light-purple'
  | 'bg-light-yellow'
  | ''

export interface ITermsAndConditions {
  style: 'cta-fill-primary'
  text: string
  target?: {
    title: string
    detailText: string
    media: TMedia[]
  }
}

export interface IHotzonesSettings {
  lightContrast?: boolean
}

interface ILXTeaser extends IBasePlacementItem {
  type: 'LXTeaser'
  promoteToH1?: boolean
  gridPositioning?: IGridPositioning
  media: TMedia[]
  settings?: OverlaySettings
  teaserIcon: string
  teaserPreTitle: string
  teaserTitle1: string
  teaserTitle2: string
  teaserTitle3: string
  teaserTitle4: string
  teaserText1: string
  teaserOverlay1TextAlign: TOverlayTextAlign
  teaserOverlay1Settings: TOverlaySettings // Text alignment
  teaserOverlay1Style: TeaserOverlayStyle // Text Color
  teaserText2: string
  teaserOverlay2TextAlign: TOverlayTextAlign
  teaserOverlay2Settings: TOverlaySettings // Text alignment
  teaserOverlay2Style: TeaserOverlayStyle // Text Color
  teaserLabelVisible: boolean
  teaserLabelText: string
  teaserLabelPosition: TTeaserLabelPositions
  teaserLabelStyle: 'col-primary' | 'col-secondary' | 'col-tertiary' | 'col-white' | '' // label color
  teaserBackground: TeaserBackGround
  teaserCountdownStart: string
  teaserCountdownUntil: string
  teaserHideExpiredCountdown: boolean
  teaserLXCallToActionSettings: ICallToAction[]
  teaserTargets?: ITeaserTargets[]
  teaserTarget?: TeaserTarget
  teaserOverlayVideo?: []
  formattedUrl?: string
  title?: string
  targetsTermsAndConditions?: ITermsAndConditions
  hotZones?: Hotzone[]
  hotZonesSettings?: IHotzonesSettings
  relatedProduct?: (ICMProductTeaser | ICMExternalProduct)[]
}

export interface OverlaySettings {
  isOverlay?: string
  overlayColor?: string
}

export interface ICMCollection extends IBasePlacementItem {
  title: string
  type: 'CMCollection'
  //viewtype: IViewType
  teasableItems: IPlacementItem[]
  gridPositioning?: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionText: string
  collectionMaxElementNumber: number
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  placementCenter?: boolean
  suppressTextsAndCtas?: boolean
  media: TMedia[]
}

export interface IAction extends IBasePlacementItem {
  idAction: string
  formattedUrl: string
  url: string
  title: string
  type: 'Action'
}

export interface CouponAction extends IBasePlacementItem {
  formattedUrl: string
  hotzones: Hotzone[]
  id: string
  idAction: string
  title: string
  type: 'coupon'
  url?: string
}

export interface ICMArticle extends IBasePlacementItem {
  type: 'CMArticle'
  title: string
  detailText: string
  formattedUrl: string
  media: TMedia[]
  url?: string
  teaserLXCallToActionSettings: ICallToAction[]
}

export interface ITableArticle {
  id?: string
  type: string
  title?: string
  detailText?: string
  media: TMedia[]
}

export interface ICMChannel extends IBasePlacementItem {
  children?: ICMChild[]
  formattedUrl: string
  navigationPath: ICMNavigationPath[]
  title: string
  teaserText: string
  type: 'CMChannel'
  media: TMedia[]
  url?: string
}

export interface ICMTeaser extends IBasePlacementItem {
  teaserTarget: ICMTeaserTarget
  teaserTargets?: ITeaserTargets[]
  type: 'CMTeaser'
  title: string
  teaserTitle?: string
  media: TMedia[]
  formattedUrl?: string
  url?: string
}

export interface ICMAlgolia extends IBasePlacementItem {
  type: 'Algolia'
  teaserLXCallToActionSettings: ICallToAction[]
  teaserPreTitle: string
  teaserTitle: string
  teaserTitle1: string
  teaserTitle2: string
  teaserTitle3: string
  teaserTitle4: string
  teaserText1: string
  teaserText2: string
  ungroupedRule: string
  groupedRule: string
  displayProductsCount: number
  productData?: IProduct[]
  media?: TMedia[]
}

export interface ICMDownload extends IBasePlacementItem {
  formattedUrl: string
  fullyQualifiedUrl: string
  title: string
  type: 'CMDownload'
  url?: string
}

export interface LXDynamicContent extends IBasePlacementItem {
  dynamicRules: DynamicRule[]
  dynamicDefault: ILXTeaser | ICMCollection
  type: 'LXDynamicContent'
  media: TMedia[]
}

export interface Hotzone {
  linkedContent?: {
    externalId?: string
    teaserTitle?: string
    name?: string
    teaserText?: string
    title?: string
  }
  alt?: string
  coords?: string
  displayAsInlineOverlay?: boolean
  inlineOverlayTheme?: string
  shape?: string
  target?: string
  productData?: IProduct[]
}

export interface ICMProductList extends IBasePlacementItem {
  externalId: string
  teaserTitle: string
  teaserText: string
  type: 'CMProductList'
  title: string
  //viewtype: IViewType
  teasableItems: IPlacementItem[]
  gridPositioning?: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionText: string
  collectionMaxElementNumber: number
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  media: TMedia[]
  productData: IProduct[]
}

export interface ICMQueryList extends IBasePlacementItem {
  title: string
  type: 'CMQueryList'
  teasableItems: IPlacementItem[]
  gridPositioning?: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionText: string
  collectionMaxElementNumber: number
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  media: TMedia[]
}

export interface TeaserTarget {
  id?: string
  idAction?: string
  name?: string
  title?: string
  type?: string
  url?: string
}

export interface ITeaserTargets {
  callToActionHash?: string
  target:
    | ICMChannel
    | ICMExternalPage
    | ICMProductTeaser
    | ICMExternalChannel
    | ICMExternalProduct
    | ICMExternalLink
    | ICMLXTeaser
}

export interface IPlacement<ItemType = IPlacementItem> {
  collectionTitle: string
  collectionSubTitle?: string
  collectionText?: string
  viewtype: IViewType
  name: string
  marginLateral: boolean
  teaserLXCallToActionSettings: ICallToAction[]
  placementReflect: boolean
  placementCenter?: boolean
  marginVertical: 'X' | 'S' | 'M' | 'L'
  backgroundColor: React.CSSProperties['backgroundColor']
  clusterTile: boolean
  placementAnimation: string
  cta?: string
  items: ItemType[]
  loaded?: boolean
  lazy?: boolean
}

export interface ITablePlacement
  extends Omit<IPlacement, 'collectionTitle' | 'teaserLXCallToActionSettings' | 'items'> {
  items: ITableArticle[]
}

export interface DashButtonProps {
  formattedUrl: string
  name: string
  teaserIcon: string
}

// static or lading page
export interface ICmsPage {
  title: string
  contentPlacements: IPlacement[]
  contentParent: {
    title: string
    children: ICMChild[]
  }
  contentChildren: ICMChild[]
  anchorData: AnchorData[]
  showAnchorMenu: boolean
  cssClassName: 'static-page-grid--default' | 'cm-grid--default' | 'page-not-found'
  alternateLinks: string[]
  analytics: {
    pageSection1: string
    pageSection2: string
  }
}

export interface AnchorData {
  title: string
  placement: string
}

export type IPlacementItem =
  | ICMArticle
  | ICMChannel
  | ICMCollection
  | ICMExternalLink
  | ICMExternalPage
  | ICMExternalProduct
  | ICMProductTeaser
  | ILXTeaser
  | LXDynamicContent
  | ICMTeaser
  | ICMHtml
  | ICMExternalChannel
  | ICMProductList
  | ICMQueryList
  | ICMAlgolia

// #region ASSERT FUNCTIONS

export const isLXTeaser = (item?: IPlacementItem | object): item is ILXTeaser =>
  !!item && 'type' in item && item?.type === 'LXTeaser'

export const isCMTeaser = (item?: IPlacementItem | object): item is ICMTeaser =>
  !!item && 'type' in item && item?.type === 'CMTeaser'

export const isCMCollection = (item?: IPlacementItem | object): item is ICMCollection =>
  !!item && 'type' in item && item?.type === 'CMCollection'

export const isCMExternalProduct = (item?: IPlacementItem | object): item is ICMExternalProduct =>
  !!item && 'type' in item && item?.type === 'CMExternalProduct'

export const isCMProductTeaser = (item?: IPlacementItem | object): item is ICMProductTeaser =>
  !!item && 'type' in item && item?.type === 'CMProductTeaser'

export const isCMArticle = (item?: IPlacementItem | object): item is ICMArticle =>
  !!item && 'type' in item && item?.type === 'CMArticle'

export const isCMChannel = (item?: IPlacementItem | object): item is ICMChannel =>
  !!item && 'type' in item && item?.type === 'CMChannel'

export const isAlgolia = (item?: IPlacementItem | object): item is ICMAlgolia =>
  !!item && 'type' in item && item?.type === 'Algolia'

export const isPictureMedia = (item?: TMedia | object): item is IPictureMedia =>
  !!item && 'type' in item && item?.type === 'CMPicture'

export const isVideoMedia = (item?: TMedia | object): item is IVideoMedia =>
  !!item && 'type' in item && item?.type === 'CMVideo'

export const isDynamicContent = (item?: IPlacementItem | object): item is LXDynamicContent =>
  !!item && 'type' in item && item?.type === 'LXDynamicContent'

export const isCMExternalPage = (item?: IPlacementItem | object): item is ICMExternalPage =>
  !!item && 'type' in item && item?.type === 'CMExternalPage'

export const isCMExternalChannel = (item?: IPlacementItem | object): item is ICMExternalChannel =>
  !!item && 'type' in item && item?.type === 'CMExternalChannel'

export const isCMProductList = (item?: IPlacementItem | object): item is ICMProductList =>
  !!item && 'type' in item && item?.type === 'CMProductList'

export const isCMQueryList = (item?: IPlacementItem | object): item is ICMQueryList =>
  !!item && 'type' in item && item?.type === 'CMQueryList'
