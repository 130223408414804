import { IPrescription } from '@features/prescription/query'
import { AppDispatch } from '../../redux/store'
import {
  closeDrawerMegaMenu,
  lastSelectedProduct,
  openDrawerCartSummary,
  openDrawerFilters,
  openDrawerMegaMenu,
  openDrawerNewsLetter,
  openDrawerProductNotificationAvailable,
  toggleHamburgerMenuOpen,
  openDrawerSearch,
  IUiState,
  productsMobileListingLayout,
  openModalSignIn,
  openModalRegistration,
  openModalAddPrescription,
  openModalDeletePrescription,
  openModalViewRX,
  openModalBookExam,
} from './slice'

export const setOpenDrawerMegaMenu = (id: string, name: string) => (dispatch: AppDispatch) => {
  dispatch(openDrawerMegaMenu({ id, name }))
}

export const setCloseDrawerMegaMenu = () => (dispatch: AppDispatch) => {
  dispatch(closeDrawerMegaMenu())
}

export const setOpenDrawerFilters = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(openDrawerFilters(value))
}

export const setOpenDrawerSearch = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(openDrawerSearch(value))
}

export const setOpenDrawerNewsLetter = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(openDrawerNewsLetter(value))
}

export const setOpenDrawerCartSummary = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(openDrawerCartSummary(value))
}

export const setOpenDrawerProductNotificationAvailable = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(openDrawerProductNotificationAvailable(value))
}

export const setLastSelectedProduct = (value: string) => (dispatch: AppDispatch) => {
  dispatch(lastSelectedProduct(value))
}

export const setHamburgerMenuOpenStatus = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(toggleHamburgerMenuOpen(value))
}

export const setProductsMobileListingLayout =
  (value: IUiState['productsMobileListingLayout']) => (dispatch: AppDispatch) => {
    dispatch(productsMobileListingLayout(value))
  }

export const setOpenModalSignIn =
  (isOpen: boolean, onLoginCallBack?: any, onCancelCallBack?: any) => (dispatch: AppDispatch) => {
    dispatch(openModalSignIn({ isOpen, onLoginCallBack, onCancelCallBack }))
  }

export const setOpenModalRegistration = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(openModalRegistration(value))
}

export const setOpenModalAddPrescription =
  (isOpen: boolean, prescription: IPrescription | null, onActionSuccess: any) => (dispatch: AppDispatch) => {
    dispatch(openModalAddPrescription({ isOpen, prescription, onActionSuccess }))
  }

export const setOpenModalDeletePrescription =
  (isOpen: boolean, prescription: IPrescription | null, onActionSuccess: any) => (dispatch: AppDispatch) => {
    dispatch(openModalDeletePrescription({ isOpen, prescription, onActionSuccess }))
  }

export const setOpenModalViewRX = (isOpen: boolean, prescription: IPrescription | null) => (dispatch: AppDispatch) => {
  dispatch(openModalViewRX({ isOpen, prescription }))
}

export const setOpenModalBookExam = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(openModalBookExam(value))
}
