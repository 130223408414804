import NextLink, { type LinkProps } from 'next/link'
import { CSSProperties } from 'react'

type Link = LinkProps & {
  children?: React.ReactNode
  className?: string
  id?: string
  target?: string
  style?: CSSProperties
}

export const Link: React.FC<Link> = ({ href, prefetch = false, children, className, id, target, ...rest }) => {
  return (
    <NextLink href={href ?? '#NOT_FOUND'} prefetch={prefetch} className={className} id={id} target={target} {...rest}>
      {children}
    </NextLink>
  )
}
