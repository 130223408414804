import React from 'react'
import clsx from 'clsx'
import styles from './styles/ProductBadges.module.scss'
import { CSSModule } from '@styles/types'

interface ProductBadgesProps {
  primaryBadge?: string | null
  secondaryBadges?: string | null
  styleOverride?: CSSModule
}

export const ProductBadges: React.FC<ProductBadgesProps> = ({ primaryBadge, secondaryBadges, styleOverride }) => {
  return (
    <div className={clsx(styles.badgeContainer, styleOverride?.badgeContainer)}>
      <>
        {primaryBadge && <div className={clsx(styles.primaryBadge, styleOverride?.primaryBadge)}>{primaryBadge}</div>}
        {secondaryBadges && (
          <div className={clsx(styles.secondaryBadges, styleOverride?.secondaryBadges)}>{secondaryBadges}</div>
        )}
      </>
    </div>
  )
}
