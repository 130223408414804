import { areEquals } from '@utils/common'

export const getHref = (currentPath: string | undefined, url: string, callToActionHash: string | undefined): string => {
  const hasActionHash = Boolean(callToActionHash)

  if (url && hasActionHash && !areEquals(currentPath || '', url)) {
    return `${url}#${callToActionHash}`
  }

  if (areEquals(currentPath || '', url) && hasActionHash) {
    return `#${callToActionHash}`
  }

  return url ?? '#NOT_FOUND_FORMATTED_URL'
}
