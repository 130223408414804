export const VideoPlayerIconSymbols = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="video-controls-icon-symbols">
      <symbol id="play-icon" viewBox="0 0 16 16">
        <path
          d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM8 15.333C3.956 15.333 0.667 12.043 0.667 8C0.667 3.956 3.957 0.667 8 0.667C12.044 0.667 15.333 3.957 15.333 8C15.333 12.044 12.043 15.333 8 15.333Z"
          fill="currentColor"
        />
        <path
          d="M11.096 7.435L6.354 4.471C6.25309 4.40806 6.13718 4.37324 6.01829 4.37016C5.8994 4.36708 5.78184 4.39584 5.6778 4.45347C5.57377 4.5111 5.48703 4.59549 5.42658 4.69792C5.36613 4.80034 5.33417 4.91707 5.334 5.036V10.964C5.33431 11.0829 5.36634 11.1995 5.42679 11.3019C5.48724 11.4043 5.57392 11.4887 5.67788 11.5464C5.78184 11.6041 5.89931 11.633 6.01816 11.6301C6.13702 11.6272 6.25295 11.5927 6.354 11.53L11.096 8.566C11.1919 8.50616 11.271 8.42289 11.3258 8.32405C11.3806 8.22521 11.4094 8.11403 11.4094 8.001C11.4094 7.88797 11.3806 7.7768 11.3258 7.67795C11.271 7.57911 11.1919 7.49484 11.096 7.435ZM6.001 10.965L6.003 5.038L10.743 8.001L6 10.964L6.001 10.965Z"
          fill="currentColor"
        />
      </symbol>
      <symbol id="pause-icon" viewBox="0 0 16 16">
        <path
          d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM8 15.333C3.956 15.333 0.667 12.043 0.667 8C0.667 3.956 3.957 0.667 8 0.667C12.044 0.667 15.333 3.957 15.333 8C15.333 12.044 12.043 15.333 8 15.333Z"
          fill="currentColor"
        />
        <path d="M6.333 4.666H5.666V11.333H6.333V4.666ZM10.333 4.666H9.666V11.333H10.333V4.666Z" fill="currentColor" />
      </symbol>
      <symbol id="sound-on-icon" viewBox="0 0 16 16">
        <path
          d="M10.846 6.82C11.16 7.136 11.334 7.555 11.334 8C11.334 8.445 11.16 8.863 10.846 9.178L11.317 9.65C11.757 9.209 12 8.623 12 8C12.0008 7.69349 11.9408 7.38986 11.8236 7.10665C11.7064 6.82344 11.5342 6.56627 11.317 6.35L10.846 6.82Z"
          fill="currentColor"
        />
        <path
          d="M12.731 4.936L12.26 5.406C12.6017 5.74585 12.8726 6.15011 13.0569 6.59538C13.2413 7.04065 13.3355 7.51808 13.334 8C13.334 8.979 12.952 9.9 12.26 10.592L12.731 11.063C13.1347 10.6618 13.4548 10.1845 13.6728 9.65871C13.8908 9.13295 14.0024 8.56917 14.001 8C14.001 6.842 13.55 5.754 12.731 4.936Z"
          fill="currentColor"
        />
        <path
          d="M14.145 3.521L13.674 3.993C14.2019 4.51797 14.6204 5.14243 14.9053 5.83023C15.1903 6.51803 15.336 7.25552 15.334 8C15.336 8.74448 15.1903 9.48197 14.9053 10.1698C14.6204 10.8576 14.2019 11.482 13.674 12.007L14.145 12.478C14.7349 11.8913 15.2027 11.1935 15.5211 10.4248C15.8395 9.65617 16.0023 8.83199 16 8C16.0024 7.16784 15.8397 6.34347 15.5213 5.57464C15.2029 4.8058 14.7351 4.10778 14.145 3.521ZM9.605 0.139999C9.48745 0.0862278 9.35667 0.0682692 9.22898 0.0883653C9.10129 0.108461 8.98235 0.165722 8.887 0.252999L2.967 5.58C2.90587 5.63527 2.82641 5.66592 2.744 5.666H0.667C0.490101 5.666 0.320447 5.73627 0.19536 5.86136C0.070273 5.98645 0 6.1561 0 6.333V9.667C0 10.034 0.299 10.333 0.667 10.333H2.744C2.827 10.333 2.906 10.363 2.967 10.419L8.887 15.747C8.98228 15.834 9.10098 15.8912 9.22842 15.9114C9.35585 15.9317 9.48643 15.9142 9.604 15.861C9.7225 15.8093 9.8232 15.7239 9.8936 15.6155C9.964 15.507 10.001 15.3803 10 15.251V0.747999C10.0007 0.619163 9.96366 0.492942 9.89347 0.384902C9.82327 0.276862 9.723 0.191728 9.605 0.139999ZM9.333 15.252L3.413 9.923C3.22936 9.75782 2.991 9.66661 2.744 9.667H0.667V6.333H2.744C2.99101 6.33349 3.22941 6.24226 3.413 6.077L9.333 0.747999V15.252Z"
          fill="currentColor"
        />
      </symbol>
      <symbol id="sound-off-icon" viewBox="0 0 12 16">
        <path
          d="M9.333 15.252L3.413 9.923C3.22951 9.75746 2.99113 9.66588 2.744 9.666H0.667V6.333H2.744C2.99115 6.33322 3.22956 6.24163 3.413 6.076L9.333 0.748002V4.666H10V0.748002C10.0007 0.619165 9.96366 0.492944 9.89347 0.384904C9.82327 0.276864 9.723 0.19173 9.605 0.140002C9.48743 0.0863603 9.35667 0.0684728 9.22901 0.0885644C9.10135 0.108656 8.98241 0.16584 8.887 0.253002L2.967 5.582C2.90572 5.63691 2.82628 5.66719 2.744 5.667H0.667C0.490274 5.667 0.320771 5.73714 0.195713 5.86201C0.0706549 5.98688 0.000264957 6.15628 0 6.333V9.666C0 10.034 0.299 10.333 0.667 10.333H2.744C2.827 10.333 2.906 10.363 2.967 10.418L8.887 15.747C8.98228 15.834 9.10098 15.8912 9.22842 15.9114C9.35585 15.9317 9.48643 15.9142 9.604 15.861C9.7225 15.8093 9.8232 15.7239 9.8936 15.6155C9.964 15.507 10.001 15.3803 10 15.251V11.333H9.333V15.252Z"
          fill="currentColor"
        />
        <path
          d="M11.903 6.235L11.432 5.764L9.667 7.528L7.903 5.764L7.432 6.235L9.196 7.999L7.432 9.764L7.903 10.235L9.667 8.471L11.432 10.235L11.903 9.764L10.139 7.999L11.903 6.235Z"
          fill="currentColor"
        />
      </symbol>
    </svg>
  )
}
