import { useDispatch } from 'react-redux'
import { NEWSLETTER_INTERACTION } from '@constants/newsletter'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus, setOpenDrawerNewsLetter } from '@features/ui/action'
import { localStorageUtil } from '@foundation/utils/storageUtil'

interface UseToggleNewsletterDrawerReturn {
  toggleNewsletterDrawer: () => void
}

export const useToggleNewsletterDrawer = (): UseToggleNewsletterDrawerReturn => {
  const dispatch = useDispatch()

  const toggleNewsletterDrawer = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))

    dispatch(setOpenDrawerNewsLetter(true))
    localStorageUtil.set(NEWSLETTER_INTERACTION, true)
  }

  return {
    toggleNewsletterDrawer,
  }
}
