import styled from '@mui/material/styles/styled'
import Link, { LinkProps } from 'next/link'
import { buttonCss, GenericButtonProps } from './Button'
import React from 'react'

export interface LinkAsButtonProps extends GenericButtonProps, LinkProps {
  dataElementId?: string
  dataDescription?: string
  href: LinkProps['href']
  external?: boolean
  children?: React.ReactNode
  target?: string
}

export const LinkAsButton = styled(
  ({ external, href, startIcon, labelText, endIcon, children, dataElementId, dataDescription, ...props }: LinkAsButtonProps) =>
    external ? (
      <a href={href as string} data-element-id={dataElementId} data-description={dataDescription} {...props}>
        {startIcon}
        {labelText || children}
        {endIcon}
      </a>
    ) : (
      <Link href={href ?? '/'} data-element-id={dataElementId} data-description={dataDescription} {...props}>
        {startIcon}
        {labelText || children}
        {endIcon}
      </Link>
    ),
  {
    shouldForwardProp: prop => prop !== 'fillType' && prop !== 'fullWidth' && prop !== 'neighbor',
  }
)(buttonCss)

export const StyledLinkAsButton = LinkAsButton
