import * as React from 'react'
import { styled, css } from '@mui/material/styles'

import { ButtonProps } from '../../../types/button'
import { customPalette } from '../../../themes/color-palette'
import { ButtonBase } from '@mui/material'
import { Prohibit } from './Button.style'
import { LinkProps } from '../../../types/link'

export interface GenericButtonProps {
  theme?: any
  labelText?: string
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  fillType?: 'fill' | 'outline'
  variant?: 'primary' | 'secondary' | 'tertiary'
  fullWidth?: boolean
  size?: 'medium' | 'big'
  loading?: boolean
}

export const variantMap = (
  theme: customPalette,
  filltype: ButtonProps['filltype'] = 'fill',
  variant: ButtonProps['variant'] = 'primary',
  parentbg: ButtonProps['parentbg'] = 'light',
  _selected?: boolean | undefined
) => {
  const primaryDarkStyle = {
    background: theme.palette.custom.black,
    color: theme.palette.custom.white,
    borderColor: theme.palette.custom.black,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.white,
    // hover
    hoverBackground: theme.palette.custom.light1.grey,
    colorHover: theme.palette.custom.white,
    borderColorhover: theme.palette.custom.light1.grey,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.white,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const primaryWhiteStyle = {
    background: theme.palette.custom.white,
    color: theme.palette.custom.black,
    borderColor: theme.palette.custom.white,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.black,
    // hover
    hoverBackground: theme.palette.custom.light1.grey,
    colorHover: theme.palette.custom.white,
    borderColorhover: theme.palette.custom.light1.grey,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.white,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const primaryDarkHoverVariant = {
    background: theme.palette.custom.black,
    color: theme.palette.custom.white,
    borderColor: theme.palette.custom.black,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.white,
    // hover
    hoverBackground: theme.palette.custom.light2.grey,
    colorHover: theme.palette.custom.black,
    borderColorhover: theme.palette.custom.light2.grey,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.black,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const primaryWhiteHoverVariant = {
    background: theme.palette.custom.white,
    color: theme.palette.custom.black,
    borderColor: theme.palette.custom.white,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.black,
    // hover
    hoverBackground: theme.palette.custom.light2.grey,
    colorHover: theme.palette.custom.black,
    borderColorhover: theme.palette.custom.light2.grey,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.black,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const secondaryDarkStyle = {
    background: 'transparent',
    color: theme.palette.custom.black,
    borderColor: theme.palette.custom.black,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.black,
    // hover
    hoverBackground: theme.palette.custom.light1.grey,
    colorHover: theme.palette.custom.white,
    borderColorhover: theme.palette.custom.grey,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.white,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const secondaryWhiteStyle = {
    background: 'transparent',
    color: theme.palette.custom.white,
    borderColor: theme.palette.custom.white,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.white,
    // hover
    hoverBackground: theme.palette.custom.grey,
    colorHover: theme.palette.custom.white,
    borderColorhover: theme.palette.custom.white,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.white,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const tertiaryDarkStyle = {
    background: theme.palette.custom.blue,
    color: theme.palette.custom.white,
    borderColor: theme.palette.custom.blue,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.white,
    // hover
    hoverBackground: theme.palette.custom.light2.blue,
    colorHover: theme.palette.custom.black,
    borderColorhover: theme.palette.custom.light2.blue,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.black,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const tertiaryWhiteStyle = {
    background: 'transparent',
    color: theme.palette.custom.black,
    borderColor: 'transparent',
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.black,
    // hover
    hoverBackground: 'transparent',
    colorHover: theme.palette.custom.blue,
    borderColorhover: 'transparent',
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.blue,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const secondaryDarkHoverVariant = {
    background: 'transparent',
    color: theme.palette.custom.black,
    borderColor: theme.palette.custom.black,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.black,
    // hover
    hoverBackground: 'transparent',
    colorHover: theme.palette.custom.light1.grey,
    borderColorhover: theme.palette.custom.light1.grey,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.light1.grey,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  const secondaryWhiteHoverVariant = {
    background: 'transparent',
    color: theme.palette.custom.white,
    borderColor: theme.palette.custom.white,
    iconStrokeColor: 'transparent',
    iconFillColor: theme.palette.custom.white,
    // hover
    hoverBackground: theme.palette.custom.light1.grey,
    colorHover: theme.palette.custom.white,
    borderColorhover: theme.palette.custom.white,
    iconStrokeColorHover: 'transparent',
    iconFillColorHover: theme.palette.custom.white,
    // disable
    disableBackground: theme.palette.custom.light2.grey,
    disableColor: theme.palette.custom.white,
    disableBorderColor: theme.palette.custom.light2.grey,
    disableIconStrokeColor: 'transparent',
    disableIconFillColor: theme.palette.custom.white,
  }
  return {
    fill: {
      primary: {
        dark: primaryWhiteStyle,
        light: primaryDarkStyle,
        darkVariant: primaryWhiteHoverVariant,
        lightVariant: primaryDarkHoverVariant,
      },
      secondary: {
        dark: secondaryWhiteStyle,
        light: secondaryDarkStyle,
        darkVariant: secondaryWhiteHoverVariant,
        lightVariant: secondaryDarkHoverVariant,
      },
      tertiary: {
        dark: tertiaryWhiteStyle,
        light: tertiaryDarkStyle,
      },
    },
    outline: {
      primary: {
        dark: secondaryWhiteStyle,
        light: secondaryDarkStyle,
        darkVariant: secondaryWhiteHoverVariant,
        lightVariant: secondaryDarkHoverVariant,
      },
      secondary: {
        dark: secondaryDarkStyle,
        light: secondaryWhiteStyle,
      },
      tertiary: {
        dark: tertiaryDarkStyle,
        light: tertiaryWhiteStyle,
      },
    },
  }[filltype][variant]?.[parentbg]
}

export const variantMapLink = (theme: customPalette, parentbg: LinkProps['parentbg'] = 'light') => {
  const primaryLinkDarkStyle = {
    color: theme.palette.custom.blue,
    iconFillColor: theme.palette.custom.blue,
    vLinkColor: theme.palette.custom.purple,
    // hover
    colorHover: theme.palette.custom.black,
    iconFillColorHover: theme.palette.custom.black,
  }
  const primaryLinkWhiteStyle = {
    color: theme.palette.custom.light.blue,
    iconFillColor: theme.palette.custom.white,
    vLinkColor: theme.palette.custom.light.purple,
    // hover
    colorHover: theme.palette.custom.white,
    iconFillColorHover: theme.palette.custom.white,
  }
  return {
    dark: primaryLinkWhiteStyle,
    light: primaryLinkDarkStyle,
  }![parentbg]
}

export const buttonCss = ({
  theme,
  size,
  iconposition,
  variant,
  filltype,
  parentbg,
  selected,
  fullwidth,
  neighbor = true,
  radius = 'rounded',
}: ButtonProps) =>
  css({
    appearance: 'none',
    fontFamily: 'inherit',
    fontWeight: size === 'small' ? 700 : 400,
    fontSize: size === 'small' ? 12 : 16,
    margin: neighbor ? theme.spacing(2) : 0,
    paddingTop: size === 'big' ? theme.spacing(3) : size === 'small' ? theme.spacing(1) : theme.spacing(3),
    paddingBottom: size === 'big' ? theme.spacing(3) : size === 'small' ? theme.spacing(1) : theme.spacing(3),
    paddingLeft: size === 'small' ? theme.spacing(4) : theme.spacing(6),
    paddingRight: size === 'small' ? theme.spacing(4) : theme.spacing(6),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: size === 'big' ? theme.spacing(12) : size === 'small' ? theme.spacing(6) : theme.spacing(10),
    minWidth: size === 'small' ? theme.spacing(16) : theme.spacing(30),
    width: '100%',
    overflow: 'visible',
    border: `1px solid
    ${variantMap(theme, filltype, variant, parentbg, selected)?.borderColor || 'inherit'}`,
    color: variantMap(theme, filltype, variant, parentbg, selected)?.color || 'inherit',
    borderRadius: radius === 'rounded' ? theme.spacing(1) : 0,
    verticalAlign: 'bottom',
    cursor: 'pointer',
    textDecoration: 'none',
    background: variantMap(theme, filltype, variant, parentbg, selected)?.background || 'inherit',
    '&:disabled': {
      pointerEvents: 'none',
      background: variantMap(theme, filltype, variant, parentbg, selected)?.disableBackground || 'inherit',
      color: variantMap(theme, filltype, variant, parentbg, selected)?.disableColor || 'inherit',
      border: `1px solid
    ${variantMap(theme, filltype, variant, parentbg, selected)?.disableBorderColor || 'inherit'}`,
      svg: {
        fill: variantMap(theme, filltype, variant, parentbg, selected)?.disableIconFillColor || 'none',
        stroke: variantMap(theme, filltype, variant, parentbg, selected)?.disableIconStrokeColor || 'inherit',
      },
    },
    '.content': {
      display: 'flex',
      alignItems: 'center',
      '&-label': {
        order: 0,
        marginRight: iconposition === 'right' ? (size === 'small' ? theme.spacing(1) : theme.spacing(2)) : 0,
        marginLeft: iconposition === 'left' ? (size === 'small' ? theme.spacing(1) : theme.spacing(2)) : 0,
      },
      '&-icon': {
        display: 'flex',
        width: '1rem',
        height: '1rem',
        order: iconposition === 'right' ? 1 : 0,
        svg: {
          width: '100%',
          height: '100%',
        },
      },
    },
    svg: {
      fill: variantMap(theme, filltype, variant, parentbg, selected)?.iconFillColor || 'none',
      stroke: variantMap(theme, filltype, variant, parentbg, selected)?.iconStrokeColor || 'inherit',
    },
    '&:hover': {
      color: variantMap(theme, filltype, variant, parentbg, selected)?.colorHover || 'inherit',
      background: variantMap(theme, filltype, variant, parentbg, selected)?.hoverBackground || 'inherit',
      border: `1px solid
      ${variantMap(theme, filltype, variant, parentbg, selected)?.borderColorhover || 'inherit'}`,
      svg: {
        fill: variantMap(theme, filltype, variant, parentbg, selected)?.iconFillColorHover || 'none',
        stroke: variantMap(theme, filltype, variant, parentbg, selected)?.iconStrokeColorHover || 'inherit',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: fullwidth ? '100%' : 'auto',
    },
  })

export const linkCss = ({ theme, size, parentbg }: LinkProps) =>
  css({
    appearance: 'none',
    fontFamily: 'inherit',
    fontWeight: size === 'small' ? 700 : 400,
    fontSize: size === 'small' ? 12 : 16,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: size === 'small' ? 12 : 16,
    lineHeight: 1,
    width: 'auto',
    color: variantMapLink(theme, parentbg)?.color || 'inherit',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
    cursor: 'pointer',
    textDecoration: 'none',
    span: {
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '.content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&-label': {
        order: 0,
      },
    },
    '&:hover': {
      color: variantMapLink(theme, parentbg)?.colorHover || 'inherit',
      textDecoration: 'none',
    },
    '&:visited': {
      color: variantMapLink(theme, parentbg)?.vLinkColor || 'inherit',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  })

export const prohibit = () => {
  return (
    <Prohibit>
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <path
          fill="#fff;"
          d="M 25 0 C 11.215 0 0 11.215 0 25 s 11.215 25 25 25 s 25 -11.215 25 -25 S 38.785 0 25 0 z M 37 40 C 33 44 30 45 25 45 C 14 44 6 37 5 25 c 0 -4.703 1.555 -9.05 4 -12 L 37 40 z M 41 36 L 13 9 C 17 6 21 5 25 5 c 12 1 19 9 20 19 C 45 30 43 33 41 36 z"
        />
      </svg>
    </Prohibit>
  )
}

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop: string) =>
    ![
      'color',
      'filltype',
      'size',
      'variant',
      'iconposition',
      'parentbg',
      'radius',
      'loading',
      'labeltext',
      'neighbor',
      'enforceLabelLength',
      'fullwidth',
    ].includes(prop),
})(buttonCss)

export const Button = (props: ButtonProps) => {
  const { labeltext, disabled, renderIcon, children, enforceLabelLength = false, ...rest } = props
  const renderChildren = () => {
    const icon = renderIcon && <span className="content-icon">{renderIcon()}</span>
    const label = labeltext && (
      <span className="content-label">
        {enforceLabelLength && labeltext.length > 25 ? labeltext.substring(0, 24) : labeltext}
      </span>
    )
    const disable = disabled && prohibit()
    return (
      <>
        <div className="content">
          {icon}
          {label}
        </div>
        {disable}
      </>
    )
  }
  const content = !!children ? children : renderChildren()
  return (
    <StyledButton {...rest} disabled={disabled}>
      {content}
    </StyledButton>
  )
}
