import React from 'react'

export interface PictureProps {
  alt?: string | undefined
  deskL?: string
  deskS?: string
  tabletL?: string
  tabletP?: string
  isLazy?: boolean
  src?: string
  crops?: {
    [key: string]: {
      crop: string
      width: number
      height: number
    }
  }
}

/**
 * NOTE: this component is for temporary use only.
 * Responsive image formats and naming convention needs to be aligned with
 * white label implementation before this can be switched to CmsPicture component
 *
 * @deprecated
 */
export const Picture: React.FC<PictureProps> = ({ alt, deskL, deskS, tabletL, tabletP, src, isLazy = true, crops }) => {
  if (!src) return null
  return (
    <>
      <picture>
        {deskL && (
          <source
            media="(min-width: 1440px)"
            srcSet={deskL}
            height={crops?.deskL?.height}
            width={crops?.deskL?.width}
          />
        )}
        {deskS && (
          <source
            media="(min-width: 1280px) and (max-width: 1439px)"
            srcSet={deskS}
            height={crops?.deskS?.height}
            width={crops?.deskS?.width}
          />
        )}
        {tabletL && (
          <source
            media="(min-width: 1024px) and (max-width: 1279px)"
            srcSet={tabletL}
            height={crops?.tabletL?.height}
            width={crops?.tabletL?.width}
          />
        )}
        {tabletP && (
          <source
            media="(min-width: 601px) and (max-width: 1023px)"
            srcSet={tabletP}
            height={crops?.tabletP?.height}
            width={crops?.tabletP?.width}
          />
        )}
        <img
          src={src}
          alt={alt}
          // crossOrigin="anonymous"
          loading={isLazy ? 'lazy' : 'eager'}
          height={crops?.mobile?.height}
          width={crops?.mobile?.width}
        />
      </picture>
    </>
  )
}
