import styled from '@mui/material/styles/styled'
import React from 'react'
import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled'
import { cssAnchor } from '../Anchor/Anchor'

const ResetButtonBase = styled(ButtonUnstyled)(cssAnchor)

export const ButtonAsLink = styled((props: ButtonUnstyledProps) => <ResetButtonBase {...props} />)({
  verticalAlign: 'initial',
  lineHeight: 'initial',
  border: 'none',
  background: 'none',
  padding: 0,
  margin: 0,
})

export const StyledButtonAsLink = ButtonAsLink