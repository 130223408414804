import React from 'react'

export type LoaderProps = {
  size?: number
  extraStyle?: any
  htmlColor?: string
}

export const StyledLoader = ({ size = 16, htmlColor = '#65baaf', extraStyle = {} }: LoaderProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto',
        borderRadius: '100%',
        ...extraStyle,
      }}
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor}>
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.7486979166666667s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(15 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.7161458333333334s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(30 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.68359375s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(45 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.6510416666666667s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(60 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.6184895833333334s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(75 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.5859375s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(90 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.5533854166666667s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(105 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.5208333333333334s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(120 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.48828125s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(135 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.4557291666666667s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(150 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.42317708333333337s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(165 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.390625s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(180 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.3580729166666667s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(195 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.32552083333333337s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(210 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.29296875s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(225 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.2604166666666667s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(240 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.22786458333333334s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(255 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.1953125s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(270 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.16276041666666669s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(285 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.13020833333333334s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(300 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.09765625s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(315 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.06510416666666667s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(330 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="-0.032552083333333336s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x={48} y={-7} rx={0} ry={0} width={4} height={40} fill={htmlColor} transform="rotate(345 50 50)">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.78125s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  )
}
