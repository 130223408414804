import { sendButtonOnClickEvent } from '@foundation/analytics/tealium/lib'

export const onClickWithAnalytics = (
  e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>,
  data_element_id?: string,
  data_description?: string
) => {
  onClick?.(e)
  sendButtonOnClickEvent(data_element_id, data_description)
}
