import styled from '@mui/material/styles/styled'
import { StyledFormControlLabel } from '../../../components/UI'

export const ProductPriceContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isPDP' && prop !== 'isCompact' && prop !== 'isVerticalLayout',
  name: 'ProductPrice',
  slot: 'Container',
})<{ isPDP: boolean | null; isCompact: boolean | null; isVerticalLayout?: boolean }>(
  ({ isPDP, isCompact, isVerticalLayout, theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: isVerticalLayout ? 'column' : isPDP ? 'row' : 'initial',
    flexWrap: 'wrap',
    justifyContent: isPDP ? 'flex-end' : 'flex-start',
    alignItems: 'center',
    fontSize: theme.typography.body2.fontSize,
    gap: theme.spacing(2),
    lineHeight: 1.5,
    marginTop: theme.spacing(0.5),

    [theme.breakpoints.down(391)]: {
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'left',
      marginTop: theme.spacing(1),
    },
  })
)

export const ProductPriceDetails = styled('div', {
  name: 'ProductPrice',
  slot: 'ContentDiv',
  shouldForwardProp: prop => prop !== 'justifyContent' && prop !== 'isVerticalLayout',
})<{ justifyContent: string; isVerticalLayout?: boolean }>(({ theme, isVerticalLayout, justifyContent }) => ({
  display: 'inline-flex',
  flexDirection: isVerticalLayout ? 'column' : 'row',
  flexWrap: 'wrap',
  justifyContent,
  alignItems: 'center',
  gap: theme.spacing(1.5),
}))

export const ProductPriceContainerPDP = styled('div', {
  name: 'ProductPrice',
  slot: 'ContainerPDP',
})(({ theme }) => ({
  width: '100%',
  alignItems: 'baseline',
  display: 'flex',
  flexDirection: 'row',
  fontSize: theme.typography.h4.fontSize,
  fontWeight: 'bold',
  justifyContent: 'space-between',
  color: theme.palette.text.dark.primary,
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}))

export const PriceSection = styled('div', {
  name: 'ProductPrice',
  slot: 'PriceSection',
})(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'end',
}))

export const InitialPriceSection = styled('div', {
  name: 'ProductPrice',
  slot: 'InitialPriceSection',
})(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  flexWrap: 'wrap',
  alignItems: 'center',
}))

export const DiscountedPriceSection = styled('div', {
  name: 'ProductPrice',
  slot: 'DiscountedPriceSection',
})(({}) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'end',
}))
export const SuggestedProductPriceContainerPDP = styled('div', {
  name: 'SuggestedProductPrice',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  span: {
    marginRight: '1px',
    fontSize: '0.875rem',
    lineHeight: '1.3rem',
    '&[class$="-InitialPrice-textField"]': {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: 400,
      color: theme.palette.custom.light1.grey,
    },
  },
}))
export const ProductPriceContainerForCMS = styled('div', {
  shouldForwardProp: prop => prop !== 'isPDP' && prop !== 'isCompact',
})<{ isPDP: boolean | null; isCompact: boolean | null }>(({ isPDP, isCompact, theme }) => ({
  width: '100%',
  display: isPDP ? 'flex' : 'initial',
  flexWrap: isCompact ? 'wrap' : 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '130px',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
  },
}))

export const FavouriteProductPriceContainerPDP = styled('div', {
  name: 'FavouriteProductPrice',
  slot: 'Container',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const ProductPriceTypeName = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: theme.typography.h4.fontSize,
  fontWeight: 600,
  lineHeight: 1.4,
  textAlign: 'left',
  textTransform: 'uppercase',
  color: theme.palette.text.dark.primary,
}))

export const ProductCurrentPriceContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isPDP',
  name: 'ProductPrice',
  slot: 'CurrentPriceContainer',
})<{ isPDP: boolean | null }>(({ isPDP, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'right',
  color: theme.palette.custom.black,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: isPDP ? 600 : 400,
  marginLeft: theme.spacing(1),
}))

export const ProductPriceCurrentPrice = styled('span', {
  shouldForwardProp: (prop: string) =>
    prop !== 'isPDP' && prop !== 'isCompact' && prop !== 'isCart' && prop !== 'isStickyBar',
  name: 'ProductPrice',
  slot: 'CurrentPrice',
})<{ isPDP?: boolean; isCompact?: boolean; isCart?: boolean; isStickyBar?: boolean }>(
  ({ isPDP, isCompact, isCart, isStickyBar, theme }) => ({
    textAlign: isPDP ? 'center' : 'end',
    marginRight: theme.spacing(1),
    fontSize: isPDP
      ? theme.typography.h4.fontSize
      : isCompact
        ? theme.typography.subtitle2.fontSize
        : isCart
          ? theme.typography.h4.fontSize
          : theme.typography.body2.fontSize,
    fontWeight: isPDP ? 600 : isCart ? 600 : 700,
    lineHeight: theme.spacing(5.25),
    [theme.breakpoints.up('sm')]: {
      fontSize: isPDP || isCart ? theme.typography.h4.fontSize : theme.typography.body2.fontSize,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: isStickyBar ? theme.typography.body1.fontSize : theme.typography.body2.fontSize,
    },
  })
)

export const TotalContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'TotalContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const ProductPriceLabel = styled('span', {
  shouldForwardProp: prop => prop !== 'isPDP' && prop !== 'isStickyBar' && prop !== 'showFramePriceLabel',
  name: 'ProductPrice',
  slot: 'Label',
})<{ isPDP: boolean | null; isStickyBar?: boolean; showFramePriceLabel?: boolean }>(
  ({ theme, isPDP, isStickyBar, showFramePriceLabel }) => ({
    fontSize: isStickyBar || isPDP ? theme.typography.body1.fontSize : theme.typography.body2.fontSize,
    fontWeight: isPDP ? 600 : 700,
    textTransform: 'uppercase',
    display: 'inline-block',
    marginRight: theme.spacing(1),
    width: showFramePriceLabel ? theme.spacing(32.5) : 'fit-content',
    [theme.breakpoints.down('sm')]: {
      fontWeight: isStickyBar ? 700 : isPDP ? 600 : 400,
    },
    lineHeight: '21px',
    color: theme.palette.custom.black,
  })
)

export const ProductPriceCurrentPriceContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'CurrentPriceContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const SuggestedProductPriceCurrentPrice = styled('span', {
  name: 'SuggestedProductPrice',
  slot: 'CurrentPrice',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'normal',
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.43,
  },
}))

export const FavouriteProductPriceCurrentPrice = styled('span', {
  name: 'FavouriteProductPrice',
  slot: 'CurrentPrice',
})(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 'normal',
  lineHeight: 1.6,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.43,
  },
}))

export const SuggestedPerBoxProductPrice = styled('span')(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: 1.6,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: 1.43,
  },
}))

export const ProductPricePerBox = styled('div', {
  shouldForwardProp: prop => prop !== 'isPDP',
})<{ isPDP: boolean | null }>(({ isPDP, theme }) => ({
  fontSize: isPDP ? theme.typography.h4.fontSize : theme.typography.body2.fontSize,
  fontWeight: isPDP ? 600 : 400,
  lineHeight: '21px',
  color: theme.palette.custom.grey,
  marginRight: theme.spacing(1),
}))
export const ProductPriceCurrentPriceForCMS = styled('span', {
  shouldForwardProp: prop => prop !== 'isPDP',
})<{ isPDP: boolean | null }>(({ isPDP, theme }) => ({
  fontSize: isPDP ? theme.typography.body1.fontSize : theme.typography.subtitle2.fontSize,
  fontWeight: isPDP ? 600 : 400,
  paddingRight: theme.spacing(1),
  color: theme.palette.custom.red,
  lineHeight: theme.spacing(6),
}))

export const SuggestedProductPriceFrom = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: 400,
}))

export const ProductPriceInitialPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'InitialPrice',
  shouldForwardProp: prop => prop !== 'isCartOrPDP',
})<{ isCartOrPDP?: boolean }>(({ theme, isCartOrPDP }) => ({
  fontSize: isCartOrPDP ? theme.typography.body2.fontSize : theme.typography.subtitle2.fontSize,
  fontWeight: 400,
  lineHeight: theme.spacing(4),
  textDecoration: 'line-through',
  color: theme.palette.custom.light1.grey,
  [theme.breakpoints.up('sm')]: {
    fontSize: isCartOrPDP ? theme.typography.body2.fontSize : theme.typography.subtitle1.fontSize,
  },
}))

export const ProductPriceTotal = styled('div', {
  name: 'ProductPrice',
  slot: 'Total',
})(({}) => ({
  textAlign: 'right',
}))

export const ProductPriceInitialPricePDP = styled('span', {
  name: 'ProductPrice',
  slot: 'InitialPricePDP',
  shouldForwardProp: prop => prop !== 'isStickyBar',
})<{ isStickyBar?: boolean }>(({ theme, isStickyBar }) => ({
  fontSize: theme.typography.body2.fontSize,
  flex: '0 0 auto',
  textDecoration: 'line-through',
  padding: `0 ${theme.spacing(2.5)}`,
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('sm')]: {
    color: isStickyBar ? theme.palette.custom.light1.grey : theme.palette.text.dark.primary,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: isStickyBar ? theme.typography.subtitle1.fontSize : theme.typography.body2.fontSize,
  },
}))

export const SuggestedProductPriceInitialPricePDP = styled('span', {
  name: 'InitialPrice',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  textDecoration: 'line-through',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(3),
  },
}))

export const FavouriteProductPriceInitialPricePDP = styled('span', {
  name: 'InitialPrice',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  textDecoration: 'line-through',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  lineHeight: theme.spacing(4),
  color: theme.palette.custom.light1.grey,
}))

export const ProductPriceDiscountBox = styled('span', {
  shouldForwardProp: prop => prop !== 'isPDP',
  name: 'ProductPrice',
  slot: 'DiscountBox',
})<{ isPDP: boolean | null }>(({ theme, isPDP }) => ({
  display: 'flex',
  flex: '0 0 auto',
  padding: theme.spacing(1, 2),
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: theme.spacing(4),
  fontWeight: 700,
  backgroundColor: theme.palette.custom.light.red,
  borderRadius: theme.spacing(9.5),
  color: theme.palette.custom.dark1.red,
  width: 'fit-content',
  textTransform: 'uppercase',
  alignItems: 'center',
  marginLeft: isPDP ? theme.spacing(1) : 0,
}))

export const ProductPriceSoldOutBox = styled(ProductPriceDiscountBox, {
  name: 'ProductPrice',
  slot: 'SoldOutBox',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  backgroundColor: theme.palette.custom.light.grey,
  textTransform: 'none',
}))

export const ProductPriceDiscountBoxCart = styled(ProductPriceDiscountBox, {
  name: 'ProductPrice',
  slot: 'DiscountBoxCart',
})(() => ({
  marginLeft: 'auto',
}))

export const ProductPriceInitialPricePDPForCMS = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(3),
  flex: '0 0 auto',
  textDecoration: 'line-through',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  lineHeight: theme.spacing(4),
}))

export const ProductPriceDiscount = styled('span', {
  name: 'ProductPrice',
  slot: 'DiscountText',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 600,
  lineHeight: 1.43,
  textTransform: 'uppercase',
  color: theme.palette.text.dark.primary,
}))

export const ProductPriceDiscountBoxForCMS = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 8px',
  gap: '10px',
  width: '63px',
  height: '24px',
  background: theme.palette.custom.light.red,
  color: theme.palette.custom.dark1.red,
  borderRadius: '38px',
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: '16px',
}))

export const ProductPriceContent = styled('div', {
  name: 'ProductPrice',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: 0,
}))

export const ProductDiscountContent = styled('div', {
  name: 'ProductDiscount',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'flex-end',
  gap: theme.spacing(2),
  padding: 0,
}))

export const ProductPriceDiscountContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'DiscountContainer',
})(({ theme }) => ({
  padding: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.background.dark.primary}`,
  background: 'transparent',
}))

export const ProductPriceBoxesNr = styled('span', {
  name: 'ProductPrice',
  slot: 'BoxesNr',
})(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 'normal',
  lineHeight: 1.43,
  textAlign: 'left',
  color: '#333',
  paddingTop: theme.spacing(4),
}))

export const ProductPriceWrapper = styled('div', {
  name: 'ProductPrice',
  slot: 'Wrapper',
})(({ theme }) => ({
  minHeight: 26,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: theme.spacing(4),
}))

export const ProductPricePricePerBoxContainer = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxContainer',
})(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  alignSelf: 'stretch',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    padding: 0,
    marginBottom: theme.spacing(4),
  },
}))

export const ProductPricePricePerBox = styled('span', {
  name: 'ProductPrice',
  slot: 'PricePerBox',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: theme.typography.body2.fontSize,
  lineHeight: 1.43,
  textAlign: 'right',
  color: theme.palette.color.black,
  width: 'inherit',
}))

export const ProductPricePricePerBoxPrices = styled('div', {
  name: 'ProductPrice',
  slot: 'PricePerBoxPrices',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  padding: 0,
}))

export const ProductPriceBoxCount = styled('div', {
  name: 'ProductPrice',
  slot: 'BoxCount',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'left',
  marginBottom: theme.spacing(2),
}))

export const ProductPriceTotalRow = styled('span', {
  name: 'ProductPrice',
  slot: 'PriceTotalRow',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
}))

export const ProductAutoRefillRow = styled('div', {
  name: 'ProductPrice',
  slot: 'AutoRefillRow',
})(({ theme }) => ({
  width: '100%',
  display: 'block !important',
  backgroundColor: theme.palette.custom.light.blue,
  marginBottom: theme.spacing(4),
  div: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
    margin: theme.spacing(3),
  },
}))

export const ProductPriceDiscountRow = styled('span', {
  name: 'ProductPrice',
  slot: 'PriceDiscountRow',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: 0,
  marginTop: theme.spacing(2),
  '> span:last-of-type': {
    paddingRight: 0,
  },
}))

export const ProductPricePricePerBoxInitialPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'PricePerBoxInitialPrice',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: theme.typography.subtitle2.fontSize,
  flexGrow: 0,
  lineHeight: 1.33,
  textDecoration: 'line-through',
  textAlign: 'right',
  color: '#333',
}))

export const ProductPricePricePerBoxCurrentPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'PricePerBoxCurrentPrice',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: theme.typography.body2.fontSize,
  flexGrow: 0,
  lineHeight: 1.43,
  textAlign: 'right',
  color: '#333',
}))

export const ProductPricePricePerBoxAutoRefillPrice = styled('span', {
  name: 'ProductPrice',
  slot: 'PricePerBoxAutoRefillPrice',
})(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: theme.typography.body1.fontSize,
  lineHeight: 1.43,
  textAlign: 'right',
  color: theme.palette.custom.red,
}))

export const SubscribeFormControlLabel = styled(StyledFormControlLabel, {
  name: 'AutoRefill',
  slot: 'SubscribeFormControlLabel',
})(({ theme }) => ({
  marginTop: theme.spacing(3),
  span: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.custom.black,
    fontWeight: 700,
    lineHeight: 1.43,
    '&:first-of-type': {
      marginLeft: theme.spacing(3),
    },
  },
}))

export const KlarnaMessageContainerPDP = styled('div', {
  name: 'KlarnaMessage',
  slot: 'ContainerPDP',
})(() => ({
  position: 'relative',
}))

export const ProductPriceFrom = styled('span', {
  name: 'ProductPrice',
  slot: 'TextField',
})({
  fontSize: 12,
})
