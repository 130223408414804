import useBreakpoints from '@hooks/useBreakpoints'
import { useMemo } from 'react'

export const useGetProductImageWidth = (variant?: string): number => {
  const { isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440 } = useBreakpoints()

  return useMemo<number>(() => {
    const isCMS = variant === 'cms-products-module'
    switch (true) {
      case isCMS:
        return 400
      case isViewportWidthUnder426:
        return 213
      case isMobile:
        return 296
      case isTablet:
        return 600
      case isViewportWidthAbove1440:
        return 600
      case isDesktop:
        return 600
      default:
        return 260
    }
  }, [isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440, variant])
}
