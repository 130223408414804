import React from 'react'

// eslint-disable-next-line no-undef
function useIsInViewport<T extends Element>(ref: React.RefObject<T>, options?: IntersectionObserverInit): boolean {
  const [isIntersecting, setIntersecting] = React.useState<boolean>(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    }, options)
    const current = ref.current
    if (current) observer.observe(current)

    return () => {
      if (current) observer.unobserve(current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return isIntersecting
}

export default useIsInViewport
