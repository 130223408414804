import React from 'react'
import { Backdrop } from '@mui/material'
import { PreLoader } from './PreLoader'

export interface LoaderProps {
  size?: number
}

const DEFAULT_LOADER_SIZE = 50

/**
 * Loader with backdrop to center spinner. Internal `<PreLoader>` default size is `50`
 */
export const Loader: React.FC<LoaderProps> = ({ size = DEFAULT_LOADER_SIZE }) => {
  return (
    <Backdrop
      sx={{
        opacity: '0.5 !important',
        color: theme => theme.palette.background.light.tertiary,
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <PreLoader fill={'light'} size={size} />
    </Backdrop>
  )
}

export const LoaderSymbol = () => {
  const rects = Array.from({ length: 24 }, (_, i) => i * 15)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="loader-container">
      <symbol id="loader-symbol" viewBox="0 0 100 100">
        {rects.map((angle, i) => (
          <rect
            key={i}
            className={`loader-rect loader-rect-${i + 1}`}
            transform={`rotate(${angle} 50 50)`}
            x="48"
            y="-7"
            rx="0"
            ry="0"
            width="4"
            height="40"
            fill="#65baaf"
          />
        ))}
      </symbol>
    </svg>
  )
}

export const StyledLoader: React.FC<{ size?: number; extraStyle?: any; htmlColor?: string }> = ({ size }) => {
  return (
    <svg className="loader" width={size} height={size}>
      <use xlinkHref="#loader-symbol" />
    </svg>
  )
}
