import React from 'react'
import Head from 'next/head'

export interface SeoProps {
  deskL?: string
  deskS?: string
  tabletL?: string
  tabletP?: string
  mobile?: string
}

export const Seo: React.FC<SeoProps> = ({ deskL, deskS, tabletL, tabletP, mobile }) => {
  const deskSMaxWidth = deskL ? '1439px' : '1440px'
  const tabletLMaxWidth = deskS && deskS !== tabletL ? '1279px' : deskSMaxWidth
  const tabletPMaxWidth = tabletL && tabletL !== tabletP ? '1023px' : tabletLMaxWidth
  const mobileMaxWidth = tabletP && tabletP !== mobile ? '600px' : tabletPMaxWidth

  return (
    <Head>
      {deskL && (
        <link key={deskL} rel="preload" as="image" media="(min-width: 1440px)" href={deskL} fetchpriority={'high'} />
      )}
      {deskS && (
        <link
          key={deskS}
          rel="preload"
          as="image"
          media={`(min-width: 1280px) and (max-width: ${deskSMaxWidth})`}
          href={deskS}
          fetchPriority={'high'}
        />
      )}
      {tabletL && (
        <link
          key={tabletL}
          rel="preload"
          as="image"
          media={`(min-width: 1024px) and (max-width: ${tabletLMaxWidth})`}
          href={tabletL}
          fetchpriority={'high'}
        />
      )}
      {tabletP && (
        <link
          key={tabletP}
          rel="preload"
          as="image"
          media={`(min-width: 601px) and (max-width: ${tabletPMaxWidth})`}
          href={tabletP}
          fetchpriority={'high'}
        />
      )}
      {mobile && (
        <link
          key={mobile}
          rel="preload"
          as="image"
          media={`(max-width: ${mobileMaxWidth})`}
          href={mobile}
          fetchpriority={'high'}
        />
      )}
    </Head>
  )
}
