import { LazyLoadImage } from 'react-lazy-load-image-component'
import ReactPlayer from 'react-player/lazy'
import styled from '@mui/material/styles/styled'

export const StyledPlayerContainer = styled('div', {
  name: 'VideoPlayer',
  slot: 'Container',
})({
  height: '100%',
  video: {
    objectFit: 'cover',
  },
})

export const StyledReactPlayer = styled(ReactPlayer, {
  name: 'VideoPlayer',
  slot: 'ReactPlayer',
  shouldForwardProp: prop => prop !== 'toggleMuted',
})({
  objectFit: 'cover',
})

export const StyledNoLazyImageEffectContainer = styled('span', {
  name: 'CmsCommonMediaV2',
  slot: 'NoLazyImageEffectContainer',
  shouldForwardProp: prop => prop !== 'isMobile' && prop !== 'viewType',
})<{
  isMobile: boolean
  viewType?: string
}>(({ isMobile, viewType }) => ({
  overflow: 'hidden',
  transitionProperty: 'background',
  transition: '0.3s ease-in-out',

  img: {
    height: '100%',
    width: '100%',
    transition: '0.3s ease-in-out',
    transform: viewType === 'pdp-banner' && isMobile ? 'scaleX(-1)' : 'none',

    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
}))

export const StyledNoLazyImagePlainSlider = styled('span', {
  name: 'CmsCommonMediaV2',
  slot: 'NoLazyImagePlainSlider',
  shouldForwardProp: prop => prop !== 'isMobile',
})(({ theme }) => ({
  img: {
    [theme.breakpoints.up('lg')]: {
      minWidth: '60vw',
      maxHeight: theme.spacing(101.5),
      minHeight: theme.spacing(101.5),
    },
    [theme.breakpoints.down(1441)]: {
      width: theme.spacing(216),
      height: theme.spacing(101.5),
    },
    [theme.breakpoints.down('lg')]: {
      width: '60vw',
    },
    [theme.breakpoints.down(1281)]: {
      width: theme.spacing(189.75),
    },
    [theme.breakpoints.down(1280)]: {
      width: '60vw',
    },
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(157.5),
    },
    [theme.breakpoints.down(1024)]: {
      width: '60vw',
    },
    [theme.breakpoints.down(769)]: {
      width: theme.spacing(145.5),
      height: theme.spacing(77),
    },
    [theme.breakpoints.down(768)]: {
      width: '76vw',
      height: theme.spacing(77),
    },
    [theme.breakpoints.down(321)]: {
      width: theme.spacing(65.75),
    },
  },
}))

export const StyledLazyLoadImagePDPContainer = styled(LazyLoadImage, {
  name: 'CmsCommonMediaV2',
  slot: 'LazyLoadImagePDPContainer',
  shouldForwardProp: prop => prop !== 'isMobile',
})<{
  isMobile: boolean
}>(({ isMobile, theme }) => ({
  transform: isMobile ? 'scaleX(-1)' : 'none',
  maxHeight: '496px',
  height: '496px !important',

  [theme.breakpoints.down(769)]: {
    maxHeight: '200px',
    height: '200px',
  },
}))

export const StyledLazyImageContainer = styled('span', {
  name: 'CmsCommonMediaV2',
  slot: 'LazyLoadImageContainer',
})(({ theme }) => ({
  lineHeight: '0px',
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  '&& .lazy-load-wrapper': {
    overflow: 'hidden',
    transitionProperty: 'background',
    transition: '0.3s ease-in-out',
    '&:hover': {
      background: theme.palette.color.black,
    },

    img: {
      height: '100%',
      width: '100%',
      transition: '0.3s ease-in-out',

      '&:hover': {
        transform: 'scale(1.05)',
        opacity: '0.9',
      },
    },
  },
}))

export const StyledSquatBannerContainer = styled(StyledLazyImageContainer, {
  name: 'CmsCommonMediaV2',
  slot: 'StyledSquatContainer',
})(({ theme }) => ({
  lineHeight: '0px',
  img: {
    height: '240px',
    [theme.breakpoints.between(0, 769)]: {
      height: '383px',
    },
  },
}))
