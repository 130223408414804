import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { useSearchParams } from 'next/navigation'
import { useCallback, useEffect, useState } from 'react'

type UseCountdownReturn = {
  isCountDownVisible: boolean
  countDownStartValue: number
  countDownUntilValue: number
  shouldHideExpired: boolean
  currentCountdownValue: number | null
  hours: string
  minutes: string
  seconds: string
}

const ONE_SECOND_IN_MS = 1000

const checkIfLessThanZero = (n: number): string => String(n > 0 ? n : 0).padStart(2, '0')

export const waitForSeconds = (delayInMS: number) => {
  return new Promise(resolve => setTimeout(resolve, delayInMS))
}

const useCountdown = (teaser?: ILXTeaser): UseCountdownReturn => {
  const urlParams = useSearchParams()
  const previewDate = urlParams.get('previewDate')
  const timeTravelDate = new Date(previewDate || '').getTime()

  const countdownUntil = teaser?.teaserCountdownUntil !== ''
  const countdownStart = teaser?.teaserCountdownStart
  const shouldHideExpired = !!teaser?.teaserHideExpiredCountdown
  const countdownStartValue = Number(teaser?.teaserCountdownStart) * 1000 || 0
  const countdownUntilValue = Number(teaser?.teaserCountdownUntil) * 1000 || 0

  const [now, setNow] = useState<number | null>(null)
  const [isCountdownVisible, setIsCountdownVisible] = useState(false)
  const [currentCountdownValue, setCurrentCountdownValue] = useState<number | null>(null)

  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')

  const countdownInterval = useCallback(async () => {
    const currentCountdown = currentCountdownValue || countdownUntilValue

    if (currentCountdown && now) {
      const hours = checkIfLessThanZero(Math.floor((currentCountdown % (1000 * 60 * 60 * 24 * 24)) / (1000 * 60 * 60)))
      const minutes = checkIfLessThanZero(Math.floor((currentCountdown % (1000 * 60 * 60)) / (1000 * 60)))
      const seconds = checkIfLessThanZero(Math.floor((currentCountdown % (1000 * 60)) / 1000))

      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)

      if (currentCountdownValue === null) setCurrentCountdownValue(countdownUntilValue - now)
      else {
        // run every 1 second
        await waitForSeconds(ONE_SECOND_IN_MS)
        setCurrentCountdownValue(currentCountdownValue - ONE_SECOND_IN_MS)
      }
    }
  }, [countdownUntilValue, currentCountdownValue, now])

  useEffect(() => {
    const isCountdownFinished = currentCountdownValue ? currentCountdownValue > 0 : false
    if (isCountdownVisible && countdownUntilValue > 0 && (currentCountdownValue === null || isCountdownFinished)) {
      countdownInterval()
    } else {
      if (shouldHideExpired) setIsCountdownVisible(false)
      else {
        setHours('00')
        setMinutes('00')
        setSeconds('00')
      }
    }
  }, [countdownUntilValue, countdownInterval, currentCountdownValue, isCountdownVisible, shouldHideExpired])

  useEffect(() => {
    if (now === null) {
      setNow(timeTravelDate > 0 ? timeTravelDate : new Date().getTime())
    }
  }, [timeTravelDate, now])

  useEffect(() => {
    if (now && teaser) {
      const validStartDate = now > countdownStartValue
      const validTargetDate = now < countdownUntilValue
      const startCountdownHasValue = countdownStartValue > 0
      const targetCountdownHasValue = countdownUntilValue > 0

      // if start date and countdown end date has values
      if (startCountdownHasValue && targetCountdownHasValue) {
        // if current date is after the start date and
        // current date is before the countdown end date
        // and countdown end date is not an empty string
        if (validStartDate && validTargetDate && countdownUntil) {
          setIsCountdownVisible(true)
        } else if (!shouldHideExpired && targetCountdownHasValue && now > countdownUntilValue && countdownUntil) {
          // if shouldHideExpired is set to false and
          // countdown end date has value and
          // current date is after the countdown end date
          // and countdown end date is not an empty string
          setIsCountdownVisible(true)
        }
      } else if (!shouldHideExpired && targetCountdownHasValue && now > countdownUntilValue && countdownUntil) {
        // if shouldHideExpired is set to false and
        // countdown end date has value and
        // current date is after the countdown end date
        // and countdown end date is not an empty string
        setIsCountdownVisible(true)
      } else if (teaser.teaserCountdownStart === '' && targetCountdownHasValue && validTargetDate && countdownUntil) {
        // if teaser.teaserCountdownStart is equal to empty string and
        // countdown end date has value and
        // current date is before the countdown end date
        // and countdown end date is not an empty string
        setIsCountdownVisible(true)
      } else {
        if (teaser.teaserCountdownStart === undefined && targetCountdownHasValue && validTargetDate && countdownUntil) {
          // if teaser.teaserCountdownStart is undefined and
          // countdown end date has value and
          // current date is before the countdown end date
          // and countdown end date is not an empty string
          setIsCountdownVisible(true)
        }
      }
    }
  }, [countdownStartValue, countdownUntil, countdownUntilValue, countdownStart, now, shouldHideExpired, teaser])

  /* TODO: align countdown naming in other files */
  return {
    isCountDownVisible: isCountdownVisible && !!now && now > 0 && countdownUntilValue > 0 && !!currentCountdownValue,
    countDownStartValue: now || 0,
    countDownUntilValue: countdownUntilValue,
    shouldHideExpired,
    currentCountdownValue,
    hours,
    minutes,
    seconds,
  }
}

export { useCountdown }
