import { PRODUCT_TYPES_KEYS } from '@constants/product'
import { AlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import CurrencyService from '@services/CurrencyService'
import { formatPriceValue, getFinalPrice, retrieveProductPrices } from '@utils/productPrice'
import { useTranslation } from 'next-i18next'
import React from 'react'
import {
  ProductCurrentPriceContainer,
  ProductPriceBoxesNr,
  ProductPriceContainer,
  ProductPriceContainerPDP,
  ProductPriceCurrentPrice,
  ProductPriceDetails,
  ProductPriceDiscountBox,
  ProductPriceInitialPrice,
  ProductPriceInitialPricePDP,
  ProductPriceLabel,
  ProductPricePricePerBox,
  ProductPricePricePerBoxContainer,
  ProductPricePricePerBoxCurrentPrice,
  ProductPricePricePerBoxInitialPrice,
  ProductPricePricePerBoxPrices,
  ProductPriceWrapper,
} from './ProductPrice.style'
import clsx from 'clsx'

export interface ProductPriceNew {
  price: AlgoliaPrice
  isLoading: boolean
  isPDP?: boolean
  totalBoxes?: number
  isCL?: boolean
  productQuantity?: string[]
  isCLAccessories?: boolean
  isCompact?: boolean
  isRoxable?: boolean
  productType: string
  isForSuggestedProduct?: boolean
}

const ProductPriceNew: React.FC<any> = ({
  className,
  price,
  plpStyle,
  isPDP = false,
  totalBoxes = 2,
  isCL = false,
  productQuantity,
  isCLAccessories = false,
  isCompact = false,
  isRoxable = false,
  productType,
  isForSuggestedProduct = false,
}) => {
  const { t } = useTranslation()

  const { initialPrice, currentPrice, discountAmount, hasInitialPriceDiscount } = retrieveProductPrices(price)

  const currency = price ? CurrencyService.getSymbolByName(price.currency) : ''

  const quantity = productQuantity && parseInt(productQuantity[0])

  const getPrice = (price: string | number) => {
    return currency.concat(getFinalPrice(price.toString(), totalBoxes, isCL, isCLAccessories, quantity))
  }

  const getPriceLabel = () => {
    switch (productType) {
      case PRODUCT_TYPES_KEYS.ACCESSORIES:
      case PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES:
      case PRODUCT_TYPES_KEYS.CONTACT_LENSES:
        return 'TOTAL'
      default:
        return 'FRAME'
    }
  }

  const getFromLabel = () => {
    if ((isRoxable || productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES) && plpStyle !== 'gve')
      return t('ProductTile.Labels.from')

    return ''
  }

  const getPerBoxLabel = () => {
    switch (productType) {
      case PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES:
      case PRODUCT_TYPES_KEYS.CONTACT_LENSES:
        return t('ContactLenses.Labels.perBox')
      default:
        return ''
    }
  }

  return (
    <ProductPriceWrapper
      className={clsx(className, `${initialPrice !== undefined ? 'product-price' : 'product-price no-discount'}`)}
    >
      {isPDP ? (
        <>
          {isCL && (
            <>
              <ProductPricePricePerBoxContainer>
                <ProductPricePricePerBox>{t('ContactLenses.Labels.PerBox')}</ProductPricePricePerBox>
                <ProductPricePricePerBoxPrices>
                  {hasInitialPriceDiscount && (
                    <ProductPricePricePerBoxInitialPrice>
                      {currency}
                      {formatPriceValue(+initialPrice)}
                    </ProductPricePricePerBoxInitialPrice>
                  )}
                  {currentPrice && (
                    <ProductPricePricePerBoxCurrentPrice>
                      {currency}
                      {formatPriceValue(+currentPrice)}
                    </ProductPricePricePerBoxCurrentPrice>
                  )}
                </ProductPricePricePerBoxPrices>
              </ProductPricePricePerBoxContainer>
              <ProductPriceBoxesNr>
                {!!totalBoxes && t('ContactLenses.Labels.Boxes', { count: totalBoxes })}
              </ProductPriceBoxesNr>
            </>
          )}
          <ProductPriceContainerPDP>
            <ProductPriceLabel isPDP={isPDP}>{!isForSuggestedProduct && getPriceLabel()}</ProductPriceLabel>
            <div>
              {hasInitialPriceDiscount && (
                <>
                  {discountAmount && discountAmount > 0 && (
                    <>
                      <ProductPriceDiscountBox isPDP={isPDP!}>
                        {Math.ceil(discountAmount)} {plpStyle !== 'gve' && '% OFF'}
                      </ProductPriceDiscountBox>
                    </>
                  )}
                  <ProductPriceInitialPricePDP>{getPrice(initialPrice)}</ProductPriceInitialPricePDP>
                </>
              )}
              {currentPrice && (
                <ProductPriceCurrentPrice isPDP={isPDP} isCompact={isCompact}>
                  {getPrice(currentPrice)}
                </ProductPriceCurrentPrice>
              )}
            </div>
          </ProductPriceContainerPDP>
        </>
      ) : (
        <ProductPriceContainer isPDP={isPDP} isCompact={isCompact}>
          <ProductPriceDetails justifyContent={isPDP ? 'right' : 'left'}>
            {hasInitialPriceDiscount && (
              <>
                {getFromLabel()}
                <ProductPriceInitialPrice>
                  {currency}
                  {formatPriceValue(+initialPrice)}
                </ProductPriceInitialPrice>
              </>
            )}
            {currentPrice && (
              <ProductCurrentPriceContainer isPDP={isPDP!}>
                <ProductPriceCurrentPrice isPDP={isPDP!} isCompact={isCompact!}>
                  {currency}
                  {formatPriceValue(+currentPrice)}
                </ProductPriceCurrentPrice>
              </ProductCurrentPriceContainer>
            )}
            {getPerBoxLabel()}
          </ProductPriceDetails>
          {!!discountAmount && discountAmount > 0 && (
            <ProductPriceDiscountBox isPDP={isPDP!}>{Math.ceil(discountAmount)}% OFF</ProductPriceDiscountBox>
          )}
        </ProductPriceContainer>
      )}
    </ProductPriceWrapper>
  )
}

export default ProductPriceNew
