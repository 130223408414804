import {
  setAnalysisResults as setAnalysisResultsAction,
  setFrameAdvisorOpenStatus as setFrameAdvisorOpenStatusAction,
  setLoadedScriptsStatus as setLoadedScriptsStatusAction,
  setSizeAdvisorOpenStatus as setSizeAdvisorOpenStatusAction,
} from '@features/frameGenius/actions'
import { RootReducerState } from '@redux/rootReducer'
import { SuggestedFeatures } from '@typesApp/FrameGenius/frameGenius'
import FrameAdvisorUtil from '@utils/FrameGenius/FrameAdvisorUtil'
import FrameGeniusUtil from '@utils/FrameGenius/FrameGeniusUtil'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

export type FrameGeniusType = {
  areScriptsLoaded: boolean
  setLoadedScriptsStatus: (status: boolean) => void

  analysisResults: SuggestedFeatures
  setAnalysisResults: (suggestedFeatures: SuggestedFeatures) => void

  isSizeAdvisorOpen: boolean
  openSizeAdvisor: () => void
  closeSizeAdvisor: () => void

  isFrameAdvisorOpen: boolean
  openFrameAdvisor: () => void
  closeFrameAdvisor: () => void

  setFrameAdvisorOpenStatus: (status: boolean) => void
}

export const useFrameGenius = (): FrameGeniusType => {
  const { areScriptsLoaded, analysisResults, isSizeAdvisorOpen, isFrameAdvisorOpen } = useSelector(
    (s: RootReducerState) => s.frameGenius
  )
  const dispatch = useDispatch()

  const openFrameAdvisor = useCallback(() => {
    FrameAdvisorUtil.setFrameAdvisorInitializedStatus(true)
    dispatch(setFrameAdvisorOpenStatusAction(true))
  }, [dispatch])

  const closeFrameAdvisor = useCallback(() => {
    FrameAdvisorUtil.setFrameAdvisorInitializedStatus(false)
    dispatch(setFrameAdvisorOpenStatusAction(false))
  }, [dispatch])

  const openSizeAdvisor = useCallback(() => dispatch(setSizeAdvisorOpenStatusAction(true)), [])
  const closeSizeAdvisor = useCallback(() => dispatch(setSizeAdvisorOpenStatusAction(false)), [])

  const setAnalysisResults = useCallback(
    (analyticsResults: SuggestedFeatures) => {
      dispatch(setAnalysisResultsAction(analyticsResults))
    },
    [dispatch]
  )

  const setLoadedScriptsStatus = useCallback(
    (isLoaded: boolean) => {
      dispatch(setLoadedScriptsStatusAction(isLoaded))
    },
    [dispatch]
  )

  const setFrameAdvisorOpenStatus = useCallback(
    (isOpen: boolean) => {
      dispatch(setFrameAdvisorOpenStatusAction(isOpen))
    },
    [dispatch]
  )

  return {
    areScriptsLoaded,
    setLoadedScriptsStatus: setLoadedScriptsStatus,

    analysisResults,
    setAnalysisResults: setAnalysisResults,

    isSizeAdvisorOpen,
    openSizeAdvisor,
    closeSizeAdvisor,

    isFrameAdvisorOpen,
    openFrameAdvisor,
    closeFrameAdvisor,
    setFrameAdvisorOpenStatus,
  }
}

export const useInitFrameGenius = () => {
  const { isFrameAdvisorOpen, setAnalysisResults, setFrameAdvisorOpenStatus, closeFrameAdvisor } = useFrameGenius()
  const router = useRouter()
  const isFAopen = useRef(isFrameAdvisorOpen)
  useEffect(() => {
    const isFrameAdvisorOpen = isFAopen.current
    const needsReinit = FrameAdvisorUtil.needsReinit()
    if (needsReinit !== isFrameAdvisorOpen) {
      setFrameAdvisorOpenStatus(needsReinit)
    }
  }, [setFrameAdvisorOpenStatus])

  useEffect(() => {
    if (FrameAdvisorUtil.isUserInExcludedPage()) {
      closeFrameAdvisor()
    }
  }, [router.pathname, closeFrameAdvisor])

  useEffect(() => {
    const suggestedFeaturesInStorage = FrameGeniusUtil.getAnalysisResults()
    setAnalysisResults(suggestedFeaturesInStorage)
  }, [setAnalysisResults])
}
