import { createSelector } from '@reduxjs/toolkit'
import { RootReducerState } from '@redux/rootReducer'

export const seoSelector = (state: RootReducerState) => {
  return state.seo || ''
}

export const seoDataSelector = (state: RootReducerState) => {
  return state.seo.seoData
}

const currentPageSeoDataSelector = () => createSelector(seoDataSelector, result => result)

export { currentPageSeoDataSelector }
