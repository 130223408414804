import { useFrameGenius } from '@hooks/useFrameGenius'
import { useToggleNewsletterDrawer } from '@hooks/useToggleNewsletterDrawer'

export const useExternalsActions = (): {
  [action: string]: () => void
} => {
  const { openFrameAdvisor, openSizeAdvisor } = useFrameGenius()
  const { toggleNewsletterDrawer } = useToggleNewsletterDrawer()

  const mappedActions = {
    'frame-advisor': openFrameAdvisor,
    'size-advisor': openSizeAdvisor,
    'newsletter-signup-overlay': toggleNewsletterDrawer,
  }

  return mappedActions
}
