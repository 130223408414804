import { PRODUCT_TYPES_MAP } from '@constants/product'
import { isContactLenses } from './product'
import { getBrand, getCLBrand, getModelName, getNormalizedProductType } from './productAttributes'
import {
  getBrand as getAlgoliaBrand,
  getModelName as getAlgoliaModelName,
  getCLBrand as getAlgoliaCLBrand,
  getProductType,
} from './productAttributesAlgolia'

enum PRODUCT_TYPE_ALT_LABEL {
  'accessories' = 'Accessories',
  'contact-lenses' = 'Contacts',
  'glasses' = 'Glasses',
  'optical' = 'Glasses', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  'sunglasses' = 'Sunglasses',
  'sun' = 'Sunglasses', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
}

export const getProductImageAltLabel = (product, isAlgolia?: boolean): string | undefined => {
  if (!product) return undefined

  let attributes: { brand: string; clBrand: string; productName: string; normalizedProductType: string }

  if (isAlgolia) {
    attributes = {
      brand: getAlgoliaBrand(product),
      clBrand: getAlgoliaCLBrand(product),
      productName: getAlgoliaModelName(product),
      normalizedProductType: PRODUCT_TYPES_MAP[getProductType(product).toLowerCase()],
    }
  } else {
    attributes = {
      brand: getBrand(product),
      clBrand: getCLBrand(product),
      productName: getModelName(product),
      normalizedProductType: getNormalizedProductType(product),
    }
  }

  const { brand, clBrand, productName, normalizedProductType } = attributes
  const isContacts = isContactLenses(normalizedProductType)
  const productType = PRODUCT_TYPE_ALT_LABEL[normalizedProductType] ?? ''

  // example: Oakley Glasses OX5140 TIE BAR™ 0.5
  const altLabel = `${isContacts ? clBrand : brand} ${productType} ${productName}`

  return altLabel.trim()
}
