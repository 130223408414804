import { AppDispatch } from '../../redux/store'
import { SuggestedFeatures } from '../../types/FrameGenius/frameGenius'
import {
  setLoadedScripts,
  setSizeAdvisorOpen,
  setFrameAdvisorOpen,
  setAnalysisResults as setAnalysisResultsAction,
} from './slice'

export const setLoadedScriptsStatus = (isLoaded: boolean) => (dispatch: AppDispatch) => {
  dispatch(setLoadedScripts(isLoaded))
}

export const setSizeAdvisorOpenStatus = (isSAopen: boolean) => (dispatch: AppDispatch) => {
  dispatch(setSizeAdvisorOpen(isSAopen))
}

export const setFrameAdvisorOpenStatus = (isFAopen: boolean) => (dispatch: AppDispatch) => {
  dispatch(setFrameAdvisorOpen(isFAopen))
}

export const setAnalysisResults = (analyticsResults: SuggestedFeatures) => (dispatch: AppDispatch) => {
  dispatch(setAnalysisResultsAction(analyticsResults))
}
