import { Typography, TypographyProps } from '@mui/material'
import React from 'react'
import styled from '@mui/material/styles/styled'

export interface StyledTypographyCustomProps extends TypographyProps {
  fontColor?: string
  fontSize?: number
  fontWeight?: string
  isUnderlined?: boolean
  isUppercased?: boolean
  lineHeight?: string
  margin?: string
  thememode?: string
  component?: string
  textDecoration?: string
}

export const StyledTypography = styled(({ ...props }) => <Typography {...props} />, {
  shouldForwardProp: prop => prop !== 'fontColor' && prop !== 'isUnderlined' && prop !== 'isUppercased',
})<StyledTypographyCustomProps>`
  ${({ fontColor, fontSize, fontWeight, isUnderlined, isUppercased, lineHeight, margin, textDecoration }) => `
  ${margin ? `margin: ${margin};` : ''}
  ${isUnderlined ? 'text-decoration: underline;' : ''}
  ${isUppercased ? 'text-transform: uppercase;' : ''}
  ${fontColor ? `color: ${fontColor};` : ''}
  ${fontSize ? `font-size: ${fontSize};` : ''}
  ${fontWeight ? `font-weight: ${fontWeight};` : ''}
  ${lineHeight ? `line-height: ${lineHeight};` : ''}
  ${textDecoration ? `text-decoration: ${textDecoration};` : ''}
`}
`
