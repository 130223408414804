import config from '@configs/index'

//Icons
export const CMS_ICONS = {
  ICON_ADDRESS: `${config.storePrefix}-icon--address`,
  ICON_ANTI_STATIC_COATING: `${config.storePrefix}-icon--anti_static_coating`,
  ICON_BAG_CHECKOUT: `${config.storePrefix}-icon--bag_checkout`,
  ICON_BETTER_CLARITY: `${config.storePrefix}-icon--better_clarity`,
  ICON_CHECKMARK_ROUNDED: `${config.storePrefix}-icon--checkmark_rounded`,
  ICON_CHECKMARK_SMALL: `${config.storePrefix}-icon--check-small`,
  ICON_COMFORTABLE_LENS: `${config.storePrefix}-icon--comfortable-lens`,
  ICON_DISPLAY: `${config.storePrefix}-icon--display`,
  ICON_EYE_GLASSES: `${config.storePrefix}-icon--eye_glasses`,
  ICON_EYEGLASSES_DARK: `${config.storePrefix}-icon--eyeglasses-dark`,
  ICON_QUESTION: `${config.storePrefix}-icon--question`,
  ICON_PRESCRIPTION: `${config.storePrefix}-icon--prescription`,
  ICON_PRESCRIPTION_SMALL: `${config.storePrefix}-icon--prescription-small`,
  ICON_PRESCRIPTION_LARGE: `${config.storePrefix}-icon--prescription-large`,
  ICON_SEARCH: `${config.storePrefix}-icon--search`,
  ICON_INFO: `${config.storePrefix}-icon--info`,
  ICON_HEART_FILLED: `${config.storePrefix}-icon--heart_filled`,
  ICON_HEART_EMPTY: `${config.storePrefix}-icon--heart_empty`,
  ICON_SHOW: `${config.storePrefix}-icon--show`,
  ICON_GREEN_SHIPPING: `${config.storePrefix}-icon--green_shipping`,
  ICON_AFTER_CARE: `${config.storePrefix}-icon--after_care`,
  ICON_BABY_SMILE: `${config.storePrefix}-icon--baby_smile`,
  ICON_CALENDAR: `${config.storePrefix}-icon--calendar`,
  ICON_CHAT: `${config.storePrefix}-icon--chat`,
  ICON_CHOOSE: `${config.storePrefix}-icon--choose`,
  ICON_CLOCK: `${config.storePrefix}-icon--clock`,
  ICON_CONTACT_LENS: `${config.storePrefix}-icon--contact_lens`,
  ICON_CONTACT_LENS_FINGER: `${config.storePrefix}-icon--contact_lens_finger`,
  ICON_CREDIT_CARD: `${config.storePrefix}-icon--credit_card`,
  ICON_CREDIT_CARD_SECURE: `${config.storePrefix}-icon--credit_card_secure`,
  ICON_DROP: `${config.storePrefix}-icon--drop`,
  ICON_EVERYDAY_COMFORT: `${config.storePrefix}-icon--everyday-comfort`,
  ICON_EYE: `${config.storePrefix}-icon--eye`,
  ICON_EYE_SMALL: `${config.storePrefix}-icon--eye-small`,
  ICON_EYE_CARE: `${config.storePrefix}-icon--eye_care`,
  ICON_EYEWEAR_RANGE: `${config.storePrefix}-icon--eyewear_range`,
  ICON_EXTRA_CLEANER: `${config.storePrefix}-icon--extra-cleaning`,
  ICON_FACE_GLASSES_SMILE: `${config.storePrefix}-icon--face_glasses_smile`,
  ICON_FACE_OVAL: `${config.storePrefix}-icon--face_oval`,
  ICON_FACE_OVAL_THICK: `${config.storePrefix}-icon--face_oval_thick`,
  ICON_FACE_RECTANGLE: `${config.storePrefix}-icon--face_rectangle`,
  ICON_FACE_RECTANGLE_SLIM: `${config.storePrefix}-icon--face_rectangle_slim`,
  ICON_FACE_RECTANGLE_THICK: `${config.storePrefix}-icon--face_rectangle_thick`,
  ICON_FACE_ROUND: `${config.storePrefix}-icon--face_round`,
  ICON_FACE_ROUND_THICK: `${config.storePrefix}-icon--face_round_thick`,
  ICON_FACE_SLIM: `${config.storePrefix}-icon--face_slim`,
  ICON_FACE_SQUARE: `${config.storePrefix}-icon--face_square`,
  ICON_FACE_TRIANGLE: `${config.storePrefix}-icon--face_triangle`,
  ICON_FRAME_ADVISOR: `${config.storePrefix}-icon--frame_advisor`,
  ICON_FREE_RETURN: `${config.storePrefix}-icon--free_return`,
  ICON_GLASSES: `${config.storePrefix}-icon--glasses`,
  ICON_GLASSES_CASE: `${config.storePrefix}-icon--glasses_case`,
  ICON_GLASSES_CROSS: `${config.storePrefix}-icon--glasses_cross`,
  ICON_GLOBAL_CARE: `${config.storePrefix}-icon--global_care`,
  ICON_GLASSES_WITH_HEART_SMALL: `${config.storePrefix}-icon--glasses-with-heart-small`,
  ICON_GLASSES_ON_HAND_ICON_SMALL: `${config.storePrefix}-icon--glasses-on-hand-small`,
  ICON_GLASSES_ON_FACE_ICON_SMALL: `${config.storePrefix}-icon--face-with-glasses-small`,
  ICON_HEART_ON_HAND_SMALL: `${config.storePrefix}-icon--heart-on-hand-small`,
  ICON_RETURN: `${config.storePrefix}-icon--return`,
  ICON_LEADING_TECH: `${config.storePrefix}-icon--leading-tech`,
  ICON_LENS_ANTI_STATIC: `${config.storePrefix}-icon--anti-static`,
  ICON_LENS_WATER_REPELLENT: `${config.storePrefix}-icon--water-repellent`,
  ICON_LENS_SMUDGE_REPELLENT: `${config.storePrefix}-icon--smudge-repellent`,
  ICON_LENS_SCRATCH_RESISTANT: `${config.storePrefix}-icon--scratch-resistant`,
  ICON_LENS_ANTI_REFLECTION: `${config.storePrefix}-icon--anti-reflection`,
  ICON_LENS_CLEANER: `${config.storePrefix}-icon--lens_cleaner`,
  ICON_LENS_GLASS: `${config.storePrefix}-icon--lens_glass`,
  ICON_LENS_PRIMARY: `${config.storePrefix}-icon-select-lens-type-primary`,
  ICON_LENS_GLASS_LASER: `${config.storePrefix}-icon--lens_glass_laser`,
  ICON_LENS_GLASS_PHOTOCHROMIC: `${config.storePrefix}-icon--lens_glass_photochromic`,
  ICON_LENS_SUN: `${config.storePrefix}-icon--lens_sun`,
  ICON_LENS_SUN_OAKLEY: `${config.storePrefix}-icon--lens_sun_oakley`,
  ICON_LENS_SUN_RAY_BAN: `${config.storePrefix}-icon--lens_sun_ray_ban`,
  ICON_LENS_SUN_RX: `${config.storePrefix}-icon--lens_sun_rx`,
  ICON_LOG_IN: `${config.storePrefix}-icon--log-in`,
  ICON_LUXURY_GLASSES: `${config.storePrefix}-icon--luxury_glasses`,
  ICON_MASK: `${config.storePrefix}-icon--mask`,
  ICON_MASK_LARGE: `${config.storePrefix}-icon--mask-large`,
  ICON_MOBILE: `${config.storePrefix}-icon--mobile`,
  ICON_MAGNIFYING_GLASS: `${config.storePrefix}-icon--magnifying_glass`,
  ICON_MAGNIFY_EYE: `${config.storePrefix}-icon--magnify-eye-icon`,
  ICON_MITIGATE_SENSITIVITY: `${config.storePrefix}-icon-help-mitigate-sensitivity`,
  ICON_LIGHT_SENSITIVITY: `${config.storePrefix}-icon--light-sensitivity`,
  ICON_OAKLEY_GLASS: `${config.storePrefix}-icon--oakley_glass`,
  ICON_OFFER: `${config.storePrefix}-icon--offer`,
  ICON_ONE_SIGHT: `${config.storePrefix}-icon-one-sight`,
  ICON_OPTICIAN: `${config.storePrefix}-icon--optician`,
  ICON_OPTOMETRIST: `${config.storePrefix}-icon--optometrist`,
  ICON_PADLOCK: `${config.storePrefix}-icon--padlock`,
  ICON_PILOT_DARK_GLASSES: `${config.storePrefix}-icon--pilot_dark_glasses`,
  ICON_PILOT_GLASSES_METER: `${config.storePrefix}-icon--pilot_glasses_meter`,
  ICON_RAYBAN_GLASS: `${config.storePrefix}-icon--rayban_glass`,
  ICON_REPLACE: `${config.storePrefix}-icon--replace`,
  ICON_RETINAL_SCREENING: `${config.storePrefix}-icon--retinal_screening`,
  ICON_ROUND_GLASSES: `${config.storePrefix}-icon--round_glasses`,
  ICON_ROUND_GLASSES_SMALL: `${config.storePrefix}-icon--round-glasses-small`,
  ICON_ROUND_GLASSES_LARGE: `${config.storePrefix}-icon--round_glasses-large`,
  ICON_SMILING_FACE: `${config.storePrefix}-icon--smile-small`,
  ICON_SANITATION_STATION: `${config.storePrefix}-icon--sanitation-station`,
  ICON_SECURE_CHECKOUT_SMALL: `${config.storePrefix}-icon--checkout-small`,
  ICON_SHOPPING: `${config.storePrefix}-icon--shopping`,
  ICON_SMOOTH_RECTANGLE_GLASSES: `${config.storePrefix}-icon--smooth_rectangle_glasses`,
  ICON_SOCIAL_DISTANCE: `${config.storePrefix}-icon--social_distance`,
  ICON_SOCIAL_INSTAGRAM: `${config.storePrefix}-icon--instagram-icon`,
  ICON_SOCIAL_INSTAGRAM_BOLD: `${config.storePrefix}-icon--instagram-icon_bold`,
  ICON_SOCIAL_TIKTOK_BOLD: `${config.storePrefix}-icon--tiktok-icon_bold`,
  ICON_TICK: `${config.storePrefix}-icon--tick`,
  ICON_SOCIAL_FACEBOOK_BOLD: `${config.storePrefix}-icon--facebook-icon_bold`,
  ICON_SOCIAL_TWITTER_BOLD: `${config.storePrefix}-icon--twitter-icon_bold`,
  ICON_STORE_MANAGER: `${config.storePrefix}-icon--store_manager`,
  ICON_STORES: `${config.storePrefix}-icon--stores`,
  ICON_STORE: `${config.storePrefix}-icon--store`,
  ICON_STORES_LARGE: `${config.storePrefix}-icon--stores-large`,
  ICON_SUNNY: `${config.storePrefix}-icon-support-clear-vision`,
  ICON_CLEAR_VISION_BRIGHT: `${config.storePrefix}-icon--clear-vision-bright`,
  ICON_DIRECTION: `${config.storePrefix}-icon--direction`,
  ICON_TEMPLE_SIZE: `${config.storePrefix}-icon--temple_size`,
  ICON_TEST: `${config.storePrefix}-icon--test`,
  ICON_TRANSITIONS_SEAMLESS: `${config.storePrefix}-icon--seamless-transition`,
  ICON_TRANSITIONS_PROTECTION: `${config.storePrefix}-icon--always-on-protection`,
  ICON_TRANSITIONS_STYLE: `${config.storePrefix}-icon--customize-your-style`,
  ICON_TRIANGLE_DANGER: `${config.storePrefix}-icon--triangle_danger`,
  ICON_TRUCK_SMALL: `${config.storePrefix}-icon--truck-small`,
  ICON_TRUCK_LARGE: `${config.storePrefix}-icon--truck-large`,
  ICON_TRY_ON_SMALL: `${config.storePrefix}-icon--try-on-small`,
  ICON_UV_PROTECT: `${config.storePrefix}-icon-protect-your-eyes-from-uv-damage`,
  ICON_EXTENDED_DURABILITY: `${config.storePrefix}-icon--extended-durability`,
  ICON_CHAT_SMALL: `${config.storePrefix}-icon--chat-small`,
  ICON_CHAT_LARGE: `${config.storePrefix}-icon--chat-large`,
  ICON_PHONE_SMALL: `${config.storePrefix}-icon--phone-small`,
  ICON_PHONE_LARGE: `${config.storePrefix}-icon--phone-large`,
  ICON_EMAIL_SMALL: `${config.storePrefix}-icon--email-small`,
  ICON_EMAIL_LARGE: `${config.storePrefix}-icon--email-large`,
  ICON_CUSTOMER_CARE: `${config.storePrefix}-icon--customer-care`,
  ICON_CUSTOMER_CARE_SMALL: `${config.storePrefix}-icon--customer-care-small`,
  ICON_PRICE_MATCH_GUARANTEE: `${config.storePrefix}-icon-price-match-guarantee`,
  ICON_SPEEDY_SHIPPING_SMALL: `${config.storePrefix}-icon--speedy-shipping-small`,
  ICON_SHIPPING_DELAY: `${config.storePrefix}-icon--potential-delays`,
  ICON_UV_PROTECTION: `${config.storePrefix}-icon--uv_protection`,
  ICON_CONTACT_LENSES: `${config.storePrefix}-icon--contact_lens`,
  ICON_ACCESSORIES: `${config.storePrefix}-icon--accessories`,
  ICON_CLEARLY_LOGO: `${config.storePrefix}-icon--clearly_logo`,
  ICON_CLEARLY_LOGO_LIGHT: `${config.storePrefix}-icon--clearly_logo_light`,
  ICON_CLEARLY_LOGO_SMALL: `${config.storePrefix}-icon--clearly_logo_small`,
  ICON_CLEARLY_LOGO_SMALL_LIGHT: `${config.storePrefix}-icon--clearly_logo_small_light`,
  ICON_TEXT_MODULE_EYEGLASSES: `${config.storePrefix}-icon--eyeglass_logo`,
  ICON_LENS_GLASS_SECONDARY: `${config.storePrefix}-icon--lens_secondary_fill`,
  ICON_LENS_TRANSITIONS_ICON: `${config.storePrefix}-icon-transitions-lens`,
  ICON_LENS_POLARIZED: `${config.storePrefix}-icon-polarized-lens`,
  ICON_LENS_SELECT_PROGRESSIVES: `${config.storePrefix}-icon-select-progressives`,
  ICON_LENS_COMPUTER_LIGHT_FILTER: `${config.storePrefix}-icon-computer-lens-light-filter`,
  ICON_LENS_POLARIZED_PRIMARY: `${config.storePrefix}-icon-polarized-lens-primary-fill`,
  ICON_EVERYDAY_AFFORDABLE: `${config.storePrefix}-icon--everyday_affordable`,
  ICON_UVA_UVB_PROTECTION: `${config.storePrefix}-icon--uva_uvb_protection`,
  ICON_EVERCLEAR_CONTACTS: `${config.storePrefix}-icon-everclear-contacts`,
  ICON_SUBSCRIBE_SAVE: `${config.storePrefix}-icon-subscribe-save`,
  ICON_SHIPMENT_FREQUENCY: `${config.storePrefix}-icon-shipment-frequency`,
  ICON_DEREK_CARDIGAN_LOGO: `${config.storePrefix}-icon-derek-cardigan_logo`,
  ICON_MAIN_CENTRAL_LOGO: `${config.storePrefix}-icon-main-central_logo`,
  ICON_CLEARLY_BASICS_LOGO: `${config.storePrefix}-icon-clearly-basics_logo`,
  ICON_CLEARLY_GAMER_LOGO: `${config.storePrefix}-icon-clearly-gamer_logo`,
  ICON_CLEARLY_JUNIOR_LOGO: `${config.storePrefix}-icon-clearly-junior_logo`,
  ICON_REINCARNATE_LOGO: `${config.storePrefix}-icon-reincarnate_logo`,
  ICON_KAM_DHILLON_LOGO: `${config.storePrefix}-icon-kam-dhillon_logo`,
  ICON_JOSEPH_MARC_LOGO: `${config.storePrefix}-icon-joseph-marc_logo`,
  ICON_PERSPECTIVE_LOGO: `${config.storePrefix}-icon-perspective_logo`,
  ICON_: `${config.storePrefix}`,
} as const
