import styled from '@mui/material/styles/styled'
// import { gridColumns } from '../../../constants/ui'

// TODO: Find a way to use the gridColumns from the constants/ui
export const gridColumns = {
  xs: 4,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
}

// Size: px
export const gutter = {
  xs: 16,
  sm: 32,
  md: 32,
  lg: 32,
  xl: 32
}
// Size: px
export const subGutter = {
  xs: [16, 40],
  sm: [16, 40],
  md: [16, 50],
  lg: [16, 50],
  xl: [16, 50]
}
// Size: px
const outsideGutter = {
  xs: 16,
  sm: 32,
  md: 64,
  lg: 64,
  xl: 64
}

/**
 * Grid container component based on css grid
 * @param { 0 | 1 | 4 | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88 | 96} gap param to set gap container
 * @param { true | false } container param to set the outer gutter container
 * @param { true | false } noVertPadding param to remove vert padding
 */

export const setCommonStyle = (size, gap, container, subcontainer, noVertPadding = false) => {
  return {
    gridTemplateColumns: `repeat(${gridColumns[size]}, 1fr)`,
    gridColumnGap: (gap !== null && gap) || gap === 0 ? `${gap}px` : `${gutter[size]}px`,
    gridRowGap: (gap !== null && gap) || gap === 0 ? `${gap}px` : `${gutter[size]}px`,
    gridColumn: `span ${gridColumns[size]}`,
    padding: container ? `${outsideGutter[size]}px` : (subcontainer ? `${subGutter[size][0]}px ${subGutter[size][0]}px ${subGutter[size][1]}px` : 0),
    paddingBottom: container && noVertPadding ? 0 : 'auto',
    paddingTop: container && noVertPadding ? 0 : 'auto',
  }
}

const GridContainer = styled('div', {
  name: 'Grid',
  slot: 'Container',
  shouldForwardProp: (prop) => prop !== 'subcontainer' && prop !== 'gap' && prop !== 'container' && prop !== 'sx' && prop !== 'noVertPadding'
})<
  {
    subcontainer?: boolean,
    gap?: 0 | 1 | 4 | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88 | 96,
    container?: boolean,
    noVertPadding?: boolean,
  }>(({ subcontainer, gap, container, noVertPadding, theme }) => ({
    alignSelf: noVertPadding ? 'normal' : 'auto',
    display: 'grid',
    gridTemplateRows: '1fr',
    ...(setCommonStyle('xs', gap, container, subcontainer, noVertPadding)),
    [theme.breakpoints.up('sm')]: {
      ...(setCommonStyle('sm', gap, container, subcontainer, noVertPadding)),
    },
    [theme.breakpoints.up('md')]: {
      ...(setCommonStyle('md', gap, container, subcontainer, noVertPadding)),
    },
  }))

export default GridContainer
