import React, { PropsWithChildren } from 'react'
import { LoaderProps } from './preloader.type'
import theme from '../../../themes'
import { Box } from '@mui/material'

export const PreLoaderWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '85vh',
  }
  return <div style={style}>{children}</div>
}

export const PreLoader: React.FC<LoaderProps> = ({
  withButton = false,
  size = 16,
  fill = 'dark',
  paddingLeft,
  paddingRight,
}) => {
  const fillColor = fill == 'dark' ? theme.palette.custom.blue : theme.palette.custom.white
  if (withButton) {
    paddingRight = paddingRight || 2
  }

  const dur = 0.8
  const interval = 0.02

  return (
    <Box component="span" paddingLeft={paddingLeft} paddingRight={paddingRight} sx={{ height: size || '' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          margin: 'auto',
          borderRadius: '100%',
        }}
        width={size}
        height={size}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <path // NW
          d="M62.9333 23.4667C64.5043 23.4667 65.7778 22.1932 65.7778 20.6223C65.7778 19.0513 64.5043 17.7778 62.9333 17.7778C61.3624 17.7778 60.0889 19.0513 60.0889 20.6223C60.0889 22.1932 61.3624 23.4667 62.9333 23.4667Z"
          fill={fillColor}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur={`${dur}s`}
            begin="0s"
            repeatCount="indefinite"
          />
        </path>

        <path // W
          d="M72.1776 46.5778C74.1413 46.5778 75.7332 44.9859 75.7332 43.0222C75.7332 41.0586 74.1413 39.4667 72.1776 39.4667C70.2139 39.4667 68.6221 41.0586 68.6221 43.0222C68.6221 44.9859 70.2139 46.5778 72.1776 46.5778Z"
          fill={fillColor}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur={`${dur}s`}
            begin={`${interval}s`}
            repeatCount="indefinite"
          />
        </path>

        <path // SW
          d="M62.7555 69.6889C65.2101 69.6889 67.1999 67.6195 67.1999 65.0667C67.1999 62.5139 65.2101 60.4445 62.7555 60.4445C60.3009 60.4445 58.311 62.5139 58.311 65.0667C58.311 67.6195 60.3009 69.6889 62.7555 69.6889Z"
          fill={fillColor}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur={`${dur}s`}
            begin={`${interval * 2}s`}
            repeatCount="indefinite"
          />
        </path>

        <path // S
          d="M40.3553 80C43.3991 80 45.8665 77.5326 45.8665 74.4889C45.8665 71.4452 43.3991 68.9778 40.3553 68.9778C37.3116 68.9778 34.8442 71.4452 34.8442 74.4889C34.8442 77.5326 37.3116 80 40.3553 80Z"
          fill={fillColor}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur={`${dur}s`}
            begin={`${interval * 3}s`}
            repeatCount="indefinite"
          />
        </path>

        <path // SE
          d="M13.6612 60.8551C11.0908 63.2844 11.0908 67.2153 13.6612 69.6446C16.2286 72.074 20.3937 72.074 22.9611 69.6446C25.5316 67.2153 25.5316 63.2844 22.9611 60.8551C20.3937 58.4228 16.2317 58.3998 13.6612 60.8551Z"
          fill={fillColor}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur={`${dur}s`}
            begin={`${interval * 4}s`}
            repeatCount="indefinite"
          />
        </path>

        <path // E
          d="M9.06672 50.1334C13.0923 50.1334 16.3556 46.9496 16.3556 43.0222C16.3556 39.0949 13.0923 35.9111 9.06672 35.9111C5.04118 35.9111 1.77783 39.0949 1.77783 43.0222C1.77783 46.9496 5.04118 50.1334 9.06672 50.1334Z"
          fill={fillColor}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur={`${dur}s`}
            begin={`${interval * 5}s`}
            repeatCount="indefinite"
          />
        </path>

        <path // NE
          d="M18.4888 29.1556C23.0053 29.1556 26.6666 25.4147 26.6666 20.8C26.6666 16.1854 23.0053 12.4445 18.4888 12.4445C13.9724 12.4445 10.311 16.1854 10.311 20.8C10.311 25.4147 13.9724 29.1556 18.4888 29.1556Z"
          fill={fillColor}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur={`${dur}s`}
            begin={`${interval * 6}s`}
            repeatCount="indefinite"
          />
        </path>

        <path // N
          d="M40.7112 20.6222C45.7186 20.6222 49.7779 16.4833 49.7779 11.3777C49.7779 6.27218 45.7186 2.1333 40.7112 2.1333C35.7038 2.1333 31.6445 6.27218 31.6445 11.3777C31.6445 16.4833 35.7038 20.6222 40.7112 20.6222Z"
          fill={fillColor}
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur={`${dur}s`}
            begin={`${interval * 7}s`}
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </Box>
  )
}
