import { createContext, useMemo } from 'react'

export interface ContextWrapperData<T = unknown> {
  data: T
}

export const PlacementContext = createContext<ContextWrapperData<unknown>>({ data: undefined })

interface PlacementProviderProps<T = unknown> extends React.PropsWithChildren {
  initialData: T
}

/**
 * TODO: improve context typing
 */
export const PlacementContextWrapper: React.FC<PlacementProviderProps> = props => {
  const { children, initialData } = props
  const value = useMemo(() => ({ data: initialData }), [initialData])

  return <PlacementContext.Provider value={value}>{children}</PlacementContext.Provider>
}
