export const NEWSLETTER_TIMEOUT = 3000
export const NEWSLETTER_INTERACTION = 'isNewsletterDrawerUsed'


export const NEWSLETTER_DESCRIPTION_PLACEMENT = 'main_placement_1'
export const NEWSLETTER_MEDIA_PLACEMENT = 'main_placement_2'
export const NEWSLETTER_TEXTFIELD_PLACEMENT = 'main_placement_3'
export const NEWSLETTER_PRIVACY_PLACEMENT = 'main_placement_4'
export const NEWSLETTER_SUBSCRIBE_BUTTON_PLACEMENT = 'main_placement_5'

export const NEWSLETTER_TEXTFIELD_VIEWTYPE = 'newsletter-email'
export const NEWSLETTER_SUBSCRIBE_BUTTON_ACTION_NAME = 'newsletterSubscribe'

export const NEWSLETTER_SUCCESS_DESCRIPTION = 'main_placement_1'
export const NEWSLETTER_SUCCESS_PROMOCODE = 'main_placement_2'
export const NEWSLETTER_SUCCESS_CTAS = 'main_placement_3'

export const PROMO_TEXT_1 = '[PROMO_CODE_1]'
export const PROMO_TEXT_2 = '[PROMO_CODE_2]'