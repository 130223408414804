import styled from '@mui/material/styles/styled'
import { ProductVideo } from './ProductVideo'

export const ProductVideoContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'ProductVideoContainer'
})(() => ({}))

export const StyledProductVideo = styled(ProductVideo, {
  name: 'PdpDetails',
  slot: 'ProductVideo'
})(() => ({
  height: 'auto',
  maxWidth: '88%',
  maxHeight: '88%',
  paddingLeft: '6%',
}))
