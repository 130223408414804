export const CtaColor = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
}

export const CtaVariant = {
  fill: 'fill',
  outline: 'outline',
}
