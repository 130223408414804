import { useCallback, useEffect, useRef, useState } from 'react'

type TVideoProps = {
  autoplay: boolean
  mute: boolean
  forceStop: boolean
}

export const useVideoControl = ({ autoplay = false, mute = true, forceStop = false }: TVideoProps) => {
  const [isPlaying, setIsPlaying] = useState(autoplay)
  const [isMuted, setIsMuted] = useState(mute)
  const refInternal = useRef<HTMLVideoElement | null>(null)

  const handleVideoEnded = useCallback(() => {
    setIsPlaying(false)
  }, [setIsPlaying])

  const setRef = useCallback(
    (node: HTMLVideoElement | null) => {
      if (refInternal?.current) {
        refInternal.current.removeEventListener('ended', handleVideoEnded)
      }
      if (!node) return
      node.addEventListener('ended', handleVideoEnded)
      refInternal.current = node
    },
    [handleVideoEnded]
  )

  useEffect(() => {
    if (forceStop) {
      refInternal?.current?.pause()
      setIsPlaying(false)
    }
  }, [forceStop])

  const onHandlePlay = useCallback(() => {
    if (refInternal?.current) {
      refInternal.current?.play()
      setIsPlaying(true)
    }
  }, [])

  const onHandlePause = useCallback(() => {
    if (refInternal?.current) {
      refInternal.current?.pause()
      setIsPlaying(false)
    }
  }, [])

  const onHandleMute = useCallback(() => {
    if (refInternal?.current) {
      refInternal.current.muted = true
      setIsMuted(true)
    }
  }, [])

  const onHandleUnMute = useCallback(() => {
    if (refInternal?.current) {
      refInternal.current.muted = false
      setIsMuted(false)
    }
  }, [])

  return { setRef, onHandlePlay, onHandlePause, onHandleMute, onHandleUnMute, isPlaying, isMuted }
}
