import FormControlLabel from '@mui/material/FormControlLabel'
import styled from '@mui/material/styles/styled'

export const StyledFormControlLabel = styled(FormControlLabel, {
  name: 'StyledFormControl',
  slot: 'Label',
})(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    color: theme.palette.custom.cyprus,
    lineHeight: 1.5,
    marginRight: 5,
  },
  '.MuiFormControlLabel-root': {
    maxWidth: 188,
  },
  '.MuiCheckbox-root': {
    paddingTop: theme.spacing(1.5),
  },
}))
