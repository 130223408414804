import { RootReducerState } from '@redux/rootReducer'

export const openDrawerFiltersSelector = (state: RootReducerState) => state.ui.openDrawerFilters
export const openDrawerMegaMenuSelector = (state: RootReducerState) => state.ui.openDrawerMegaMenu
export const openDrawerSearchSelector = (state: RootReducerState) => state.ui.openDrawerSearch
export const openDrawerNewsLetterSelector = (state: RootReducerState) => state.ui.openDrawerNewsLetter
export const openDrawerCartSummarySelector = (state: RootReducerState) => state.ui.openDrawerCartSummary
export const openDrawerProductNotificationSelector = (state: RootReducerState) =>
  state.ui.openDrawerProductNotificationAvailable
export const hamburgerMenuOpenSelector = (state: RootReducerState) => state.ui.hamburgerMenuDrawerOpen
export const lastSelectedProductSelector = (state: RootReducerState) => state.ui.lastSelectedProduct
export const productsMobileListingLayoutSelector = (state: RootReducerState) => state.ui.productsMobileListingLayout
export const openModalSignInSelector = (state: RootReducerState) => state.ui.openModalSignIn
export const openModalRegistrationSelector = (state: RootReducerState) => state.ui.openModalRegistration
export const openModalAddPrescriptionSelector = (state: RootReducerState) => state.ui.openModalAddPrescription
export const openModalDeletePrescriptionSelector = (state: RootReducerState) => state.ui.openModalDeletePrescription
export const openModalViewRXSelector = (state: RootReducerState) => state.ui.openModalViewRX
export const openModalBookExamSelector = (state: RootReducerState) => state.ui.openModalBookExam
export const vmIsOpenSelector = (state: RootReducerState) => state.ui.vMisOpen
