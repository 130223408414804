import config from '@configs/index'
import { IVideoMedia, TMedia } from '@typesApp/cmsPlacement/Media'
import { isVideoMedia } from '@typesApp/teaser'
import { ImageCrops } from './types'
import { PictureProps } from '../Media/CmsPicture'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { TeaserLinkProps } from '@components/CmsModules/MediaOverlap/MediaOverlap'

export const BREAKPOINTS = { sm: 0, md: 768, lg: 1024, xl: 1280, xxl: 1440 } as const

export const MEDIA_QUERIES: Record<keyof ImageCrops, string> = {
  xxl: `(min-width: ${BREAKPOINTS.xxl}px)`,
  xl: `(min-width: ${BREAKPOINTS.xl}px) and (max-width: ${BREAKPOINTS.xxl - 1}px)`,
  lg: `(min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.xl - 1}px)`,
  md: `(min-width: ${BREAKPOINTS.md}px) and (max-width: ${BREAKPOINTS.lg - 1}px)`,
  sm: `(min-width: 0px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
} as const

export const IMAGE_CROPS: ImageCrops = {
  xxl: {
    crop: 'landscape_ratio9x2',
    width: 2880,
    height: 640,
  },
  xl: {
    crop: 'landscape_ratio320x71',
    width: 2560,
    height: 568,
  },
  lg: {
    crop: 'landscape_ratio64x29',
    width: 1843,
    height: 1304,
  },
  md: {
    crop: 'landscape_ratio192x71',
    width: 1382,
    height: 511,
  },
  sm: {
    crop: 'landscape_ratio374x284',
    width: 675,
    height: 511,
  },
}

export const generateCmsImageUrl = (uriTemplate: string | undefined, crop: string, width: number | string): string => {
  if (!uriTemplate) {
    return 'URI_TEMPLATE_NOT_FOUND'
  }
  const url = uriTemplate
    .replace('{cropName}', crop ?? 'CROP_NOT_FOUND')
    .replace('{width}', String(width) ?? 'WIDTH_NOT_FOUND')
  return config.cmsImageServerUrl + url
}

export const getPictureSources = (media: string[]): PictureProps['sources'] => {
  let [desktopPictureUriTemplate, mobilePictureUriTemplate] = media

  if (mobilePictureUriTemplate === undefined) {
    mobilePictureUriTemplate = desktopPictureUriTemplate
  }

  if (!desktopPictureUriTemplate) {
    return []
  }

  const sources: PictureProps['sources'] = []

  for (const mediaQueryKey in IMAGE_CROPS) {
    const crop = IMAGE_CROPS[mediaQueryKey]
    const url = generateCmsImageUrl(
      mediaQueryKey === 'sm' ? mobilePictureUriTemplate : desktopPictureUriTemplate,
      crop.crop,
      crop.width
    )

    sources.push({
      srcSet: url,
      media: MEDIA_QUERIES[mediaQueryKey],
      width: crop.width,
      height: crop.height,
    })
  }
  return sources
}

export const getVideoURL = (video: IVideoMedia | undefined): string => {
  if (!video) return ''
  const url = video.data?.uri || video.dataUrl
  const uri = url.startsWith('/') ? url : `/${url}`
  return `${config.cmsImageServerUrl}${uri}`
}

export const getVideoConfiguration = (media: TMedia[]) => {
  if (!media) return [{ src: '#MEDIA_NOT_FOUND' }]
  return media.map(cmVideo => {
    if (cmVideo.type !== 'CMVideo') return { src: '#MEDIA_NOT_FOUND' }
    const uri = cmVideo.data?.uri ?? cmVideo.dataUrl
    const url = uri.startsWith('/') ? uri : `/${uri}`

    return {
      src: `${config.cmsImageServerUrl}${url}`,
      autoplay: cmVideo.autoplay,
      loop: cmVideo.loop,
      muted: cmVideo.mute,
      controls: !cmVideo.hideControl,
      playsInline: true,
      playOnHover: cmVideo.playOnHover,
      poster: generateCmsImageUrl(cmVideo.picture.uriTemplate, 'desktop1280', 1280),
    }
  })
}

export const getMediaPictureUris = (media: TMedia[]): string[] => {
  return media.map(m => {
    if (isVideoMedia(m)) {
      return m.picture?.uriTemplate
    }
    return m.uriTemplate
  })
}

export const getFormattedToLink = (teaser: ILXTeaser | undefined) => {
  if (!teaser) return { toLink: '' }

  let formattedUrl: TeaserLinkProps = { toLink: teaser.formattedUrl ?? '' }
  if (!formattedUrl.toLink || formattedUrl.toLink === '') {
    const linkTarget = teaser.teaserLXCallToActionSettings?.[0]?.target
    if (linkTarget) {
      const isExternalLink = linkTarget.type === 'CMExternalLink'
      formattedUrl = isExternalLink
        ? { toLink: linkTarget.url, isExternalLink: true }
        : { toLink: linkTarget.formattedUrl ?? '' }
    }
  }

  return formattedUrl
}
