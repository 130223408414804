import { currentPageSeoDataSelector } from '../../features/seo/selector'
import { useSelector } from 'react-redux'
import { openDrawerSearchSelector } from '../../features/ui/selector'
import { useRouter } from 'next/router'
import {
  CART,
  WISHLIST,
  PRESCRIPTION_VERIFICATION,
  STORELOCATOR,
  SIGNIN,
  SITEMAP,
  CHECKOUT,
  CHECKOUT_CHILDREN,
  ORDER_RETURN_SELECT,
  ORDER_RETURN,
  ORDER_RETURN_CONFIRMATION,
  ORDER_RETURN_PRINT,
  ACCOUNT,
  CONTACT_US,
} from '../../constants/routes'

export const usePageType = () => {
  const router = useRouter()
  const urlIdentifier = router.asPath.split('?')[0]

  const seoData = useSelector(currentPageSeoDataSelector())
  const isSearchDrawerVisible = useSelector(openDrawerSearchSelector)

  const seoPageType: string = seoData?.page?.type || ''

  const pageTypes = {
    account: urlIdentifier.includes(ACCOUNT),
    search: isSearchDrawerVisible,
    home: urlIdentifier === '/' || urlIdentifier === '/c',
    cart: urlIdentifier === `/${CART}`,
    checkout:
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.UPLOAD_PRESCRIPTION}` ||
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.SHIPPING}` ||
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.PAYMENT}` ||
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.REORDER_SUMMARY}` ||
      urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`,
    checkoutExternal: urlIdentifier === `/${PRESCRIPTION_VERIFICATION}`,
    sitemap: urlIdentifier === `/${SITEMAP}`,
    wishlist: urlIdentifier === `/${WISHLIST}`,
    contactUs: urlIdentifier.includes(CONTACT_US),
    storeLocator: urlIdentifier === `/${STORELOCATOR}`,
    signIn: urlIdentifier === `/${SIGNIN}`,
    page404: seoPageType === 'NotFound',
    orderReturnSelect: urlIdentifier === `/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`,
    orderReturnConfirm: urlIdentifier === `/${ORDER_RETURN}/${ORDER_RETURN_CONFIRMATION}`,
    orderReturnPrint: urlIdentifier === `/${ORDER_RETURN}/${ORDER_RETURN_PRINT}`,
    plp: seoPageType === 'ProductListPage' || seoPageType === 'CategoryPage',
    pdp: seoPageType === 'ProductPage' || seoPageType === 'ItemPage',
  } as const

  type PageTypeKey = keyof typeof pageTypes

  const pageType = Object.keys(pageTypes).find(key => pageTypes[key]) as PageTypeKey | undefined

  const scrollToTopPages: PageTypeKey[] = ['account', 'pdp']

  const checkoutChild = {
    uploadPrescription: urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.UPLOAD_PRESCRIPTION}`,
    reorderSummary: urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.REORDER_SUMMARY}`,
    shipping: urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.SHIPPING}`,
    payment: urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.PAYMENT}`,
    orderConfirmation: urlIdentifier === `/${CHECKOUT}/${CHECKOUT_CHILDREN.ORDER_CONFIRMATION}`,
  } as const

  const ignoredBaseLayoutSkeletonPages = () => {
    const ignoredPages = [
      pageTypes.account,
      checkoutChild.shipping,
      checkoutChild.payment,
      checkoutChild.reorderSummary,
    ]

    return ignoredPages.some(page => page === true)
  }

  return {
    pageType,
    ignoredBaseLayoutSkeletonPages: ignoredBaseLayoutSkeletonPages(),
    isScrollToTopPage: pageType ? scrollToTopPages.includes(pageType) : false,
  }
}
