import { IVideoMedia, TMedia } from '@typesApp/cmsPlacement/Media'
import { isPictureMedia, isVideoMedia } from '@typesApp/teaser'
import clsx from 'clsx'
import { VideoPlayer } from '../VideoPlayer'
import { Picture } from './Picture'
import { ImageCropTypes, cmsImageCrops } from '@constants/ui'
import { getSrcSetsImageFromCms } from '@utils/url'
import { useSelector } from 'react-redux'
import { hostnameUrlsSelector } from '@redux/selectors/site'
import { useContext } from 'react'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { getDataElementId } from '@utils/common'
import { Anchor } from '@components/UI-CSS/Anchor'
import { getFormattedToLink } from './helpers'

type CmsMedia = {
  media: TMedia[] | undefined
  cropType: ImageCropTypes
  hasOverlay?: boolean
  onSetPlayingId?: (id?: string) => void
  playingId?: string
  canVideoBeClickable?: boolean
  lazy?: boolean
  alt?: string
  videoClassName?: string
}

export const CmsMedia: React.FC<CmsMedia> = props => {
  const { media, hasOverlay, onSetPlayingId, playingId, canVideoBeClickable, lazy, cropType, alt, videoClassName } =
    props
  const hostnameUrls = useSelector(hostnameUrlsSelector)
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const teaser = data.placement?.items?.find(isLXTeaser)

  if (!media || !cropType) return null

  const [mediaFile] = media || []
  const isVideo = isVideoMedia(mediaFile)
  const isPicture = isPictureMedia(mediaFile)

  const { mobile, tabletL, tabletP, deskL, deskS } = getSrcSetsImageFromCms(
    cropType,
    isVideo ? mediaFile?.picture?.uriTemplate : mediaFile?.uriTemplate ?? '',
    hostnameUrls
  )

  const { toLink } = getFormattedToLink(teaser)
  const viewtype = data.placement?.viewtype ?? 'default'
  const teaserName = teaser?.name?.replaceAll(' ', '_') ?? ''
  const cmsImageCommonProps = {
    'aria-label': `Placement_${teaserName} IMG link. Teaser №${0}`,
    'data-element-id': getDataElementId(viewtype, teaser?.placementCounter),
  }

  const PictureContent: React.FC = () => {
    return (
      <Picture
        alt={isPicture && !!mediaFile.alt ? mediaFile.alt : alt}
        deskL={deskL}
        deskS={deskS}
        tabletL={tabletL}
        tabletP={tabletP}
        src={mobile}
        isLazy={lazy}
        crops={cmsImageCrops[cropType]}
      />
    )
  }

  return (
    <div
      className={clsx(
        'media-wrapper',
        {
          'has-overlay': hasOverlay,
          'is-video': isVideo,
        },
        isVideo && !!videoClassName ? videoClassName : ''
      )}
    >
      {isVideo && (
        <VideoPlayer
          lazyload={false}
          media={media as IVideoMedia[]}
          onSetPlayingId={onSetPlayingId}
          playingId={playingId}
          canVideoBeClickable={canVideoBeClickable}
        />
      )}
      {isPicture ? (
        toLink ? (
          <Anchor href={toLink} className="cmsMediaLink" {...cmsImageCommonProps} variant="noAnimation">
            <PictureContent />
          </Anchor>
        ) : (
          <PictureContent />
        )
      ) : null}
    </div>
  )
}
